import React from "react";

export default function WordSearch() {
  return (
    <div>
      <h1 className="page-title">Word Search</h1>
      <div className="page-content">
        <p>Games page body content</p>
      </div>
    </div>
  );
}
