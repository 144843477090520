import Layout from "components/Layout";
import MainImage from "components/MainImage";
import Games from "components/Games";
import UserRoute from "components/UserRoute";
import { useLocation } from "react-router-dom";
import { gamesConfig } from "constants/games";
import { GameType } from "models/Game";

export default function GamesHome() {
  const {
    state: { event },
  } = useLocation() as any;
  const mapGamesToLinks = (game: any) => {
    const gameType = game.gameType ?? "FillTheBlank";
    const { link, image, color } = gamesConfig[gameType as GameType];
    return {
      link,
      image,
      color,
      label: game.gameName,
      id: game.gameId,
      points: game.points,
    };
  };
  return (
    <UserRoute>
      <Layout>
        <MainImage />
        {event && (
          <Games
            games={event.gamesSimplified.map(mapGamesToLinks)}
            event={event}
          />
        )}
      </Layout>
    </UserRoute>
  );
}
