import React, { ReactNode } from "react";
import { Button } from "components/Shared";
import { Paper, makeStyles, Grid } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "#ebf2f2",
    marginBottom: theme.spacing(4),
  },
  title: {
    fontSize: 25,
    textAlign: "center",
    padding: "10px 0",
    color: "#3f72af",
    fontWeight: 700,
    margin: 0,
  },
  container: {
    margin: "auto",
    marginTop: 20,
  },
}));

const Card = ({
  children,
  onClick,
  buttonText = "submit",
  title,
  className,
  width,
}: {
  children?: ReactNode;
  onClick?: any;
  buttonText?: string;
  title: string;
  className?: string;
  width?: number | string;
}): JSX.Element => {
  const classes = useStyles();
  return (
    <Grid container style={{ width, padding: "15px" }}>
      <Grid item xs={12} md={12} className={classes.container}>
        <Paper className={classes.paper}>
          <h3 className={classes.title}>{title}</h3>
          {children}
          {onClick &&<Button text={buttonText} onClick={onClick} />}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Card;
