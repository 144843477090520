import Layout from "components/Layout";
import MainImage from "components/MainImage";
import AdminGames from "components/AdminGames";
import AdminRoute from "components/AdminRoute";

export default function GamesHome() {
  return (
    <AdminRoute>
      <Layout>
        <MainImage />
        <AdminGames />
      </Layout>
    </AdminRoute>
  );
}
