import { useEffect, useState, useContext } from "react";
import AuthContext from "contexts/AuthContext";

import { activeEvent } from "services/events";

const useActiveEvent = () => {
  const { verify } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [events, setEvent] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const user = await verify();
        const { data } = await activeEvent(user.grade);
        setEvent(data.activeEvents);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    events,
  };
};

export default useActiveEvent;
