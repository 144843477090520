import { Game } from "models/Game";
import { useEffect, useState } from "react";
import { getGameById } from "services/games";

const useGame = ({ id }: { id: string }) => {
  const [loading, setLoading] = useState(true);
  const [game, setGame] = useState<Game | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const data = await getGameById(id);
        setGame(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    game,
  };
};

export default useGame;
