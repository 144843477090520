import * as Yup from "yup";

export const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  addressLine1: Yup.string().required("This field is required"),
  addressLine2: Yup.string(),
  city: Yup.string().required("This field is required"),
  state: Yup.string().required("This field is required"),
  postalZipCode: Yup.string().required("This field is required"),
  country: Yup.string().required("This field is required"),
  email: Yup.string().email("Invalid email").required("This field is required"),
  phone: Yup.string().required("This field is required"),
  grade: Yup.string().required("This field is required"),
  church: Yup.string().required("This field is required"),
});
