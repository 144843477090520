import axios from "axios";

export const register = (user: any) => {
  return axios.post(
    "https://authfuncapp.azurewebsites.net/api/V2/Identity/Register",
    user
  );
};


export const updateUser = (user: any) => {
  return axios.post(
    "https://authfuncapp.azurewebsites.net/api/V2/Identity/UpdateUsers",
    [user]
  );
};

// export const login = (loginInfo: { email: string; password: string }) => {
//   return axios.post(
//     "https://authfuncapp.azurewebsites.net/api/authorization",
//     loginInfo
//   );
// };


export const getProfileByEmail = ( email: string) => {
  return axios.post(
    "https://authfuncapp.azurewebsites.net/api/V2/Identity/GetProfileByEmail",
    email
  );
};
 