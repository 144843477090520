import { Game } from "models/Game";
import { useEffect, useState } from "react";
import { getAllGames } from "services/games";

const useGames = () => {
  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState<Game[] | undefined>();

  useEffect(() => {
    (async () => {
      try {
          const data = await getAllGames();
        setGames(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    games,
  };
};

export default useGames;
