import React from "react";
import DropZone from "./DropZone";
import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    text: {
      color: "#4d5053",
      padding: "10px 20px",
      lineHeight: "42px",
      fontSize: "19px",
      position: "relative",
      marginTop: 0,
      marginBottom: "1rem",
    },
  })
);

export default function Text({
  text,
  selected,
  answerArray,
  setAnswerArray,
  answers,
  dragged,
  setDragged,
}: {
  text: string[];
  selected: string;
  answerArray: string[];
  setAnswerArray: (answerArray: string[]) => void;
  answers: string[];
  dragged: boolean[];
  setDragged: (dragged: boolean[]) => void;
}): JSX.Element {
  const classes = useStyles();

  let counter = -1;
  return (
    <>
      <p className={classes.text}>
        {text.map((word: string, key: number) => {
          if (/\[[ 0-9]+]/g.test(word)) {
            counter++;
            return (
              <DropZone
                key={key}
                id={counter}
                selected={selected}
                answerArray={answerArray}
                setAnswerArray={setAnswerArray}
                answers={answers}
                dragged={dragged}
                setDragged={setDragged}
              />
            );
          } else return `${word} `;
        })}
      </p>
    </>
  );
}
