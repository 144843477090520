export const msalAppConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID ?? "",
    authority: process.env.REACT_APP_USER_FLOW,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    validateAuthority: false,
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
    storeAuthStateInCookie: true,
  },
};

export const scopes = {
  scopes: [process.env.REACT_APP_SCOPE ?? ""],
};
