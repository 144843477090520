import { Game } from "models/Game";
import axios from "axios";
export const addGame = async (game: Game) => {
  return await axios({
    method: "post",
    url: "https://gamesfunctionapp.azurewebsites.net/api/V2/Games/StoreGame",
    data: game,
  });
};
export const getGameTemp = async (type: string): Promise<Game | undefined> => {
  const { data } = await axios({
    method: "get",
    url: "https://gamesfunctionapp.azurewebsites.net/api/V2/Games",
  });
  return data.find((game: Game) => game.gameType === type);
};

export const getGameById = async (id: string) => {
  const { data } = await axios({
    method: "get",
    url: `https://gamesfunctionapp.azurewebsites.net/api/V2/Games/GetGameByGameId/${id}`,
  });
  return data;
};

export const getAllGamesByType = async ({
  gameType,
}: {
  gameType: string;
}): Promise<Game[] | undefined> => {
  const { data } = await axios({
    method: "get",
    url:
      "https://gamesfunctionapp.azurewebsites.net/api/V2/Games/GetGamesNamesByGameType/" +
      gameType,
  });
  return data;
};

export const getAllGames = async (): Promise<Game[] | undefined> => {
  const { data } = await axios({
    method: "get",
    url: "https://gamesfunctionapp.azurewebsites.net/api/V2/Games",
  });
  return data;
};

export const updateGamePoints = async ({
  eventGradeId,
  gameId,
  points,
  profileId,
  eventId,
}: {
  eventGradeId: string;
  gameId: string;
  profileId: string;
  eventId: string;
  points: number;
  }): Promise<Game[] | undefined> => {

  const { data } = await axios({
    method: "post",
    url:
      "https://authfuncapp.azurewebsites.net/api/V2/Identity/UpdateUserGamesPoints",
    data: {
      profileId,
      eventGradeId,
      eventId,
      gamesPointsSimplifieds: [
        {
          gameId,
          points,
        },
      ],
    },
  });
  return data;
};
