import React from "react";
import { createStyles, makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "inline-block",
      padding: "10px",
      fontSize: "17px",
      color: "#3498DB",
      backgroundColor: "#dcebf3",
      border: "1px solid #ebeded",
      borderRadius: "5px",
      whiteSpace: "nowrap",
      fontWeight: "bold",
      cursor: "pointer",
      width: "50%",
      margin: " 0 auto",
    },
    align: {
      textAlign: "center",
    },
  })
);

export default function Answers({
  answers,
  selected,
  setSelected,
  dragged,
  draggable,
  setDragged,
}: {
  answers: string[];
  selected: string;
  setSelected: (selected: string) => void;
  dragged: boolean[];
  setDragged: (dragged: boolean[]) => void;
  draggable:boolean;
}): JSX.Element {
  const classes = useStyles();

  const handleDrage = (e: React.DragEvent<HTMLInputElement>) => {
    if (dragged) {
      let tempDraged = [...dragged];

      tempDraged[+e.currentTarget.name] = true;
      setDragged(tempDraged);
      setSelected(e.currentTarget.value);
    }
  };

  return (
    <>
      {answers
        ? answers.map((answer: string, key: number) => (
            <Grid item xs={4} key={key} className={classes.align}>
              <input
                type="button"
                name={key.toString()}
                draggable={draggable}
                onDragStart={handleDrage}
                className={classes.root}
                value={answer}
              />
            </Grid>
          ))
        : null}
    </>
  );
}
