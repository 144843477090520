import React from "react";

export default function ColoringGame() {
  return (
    <>
      <h1 className="page-title">Coloring Game</h1>
      <div className="page-content"></div>
    </>
  );
}
