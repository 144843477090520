import { Box, Grid, Chip, makeStyles } from "@material-ui/core";
import Layout from "components/Layout";
import type { Event } from "models/Event";
import { TextField, Card, Select } from "components/Shared";
import { FieldType, Option } from "models/Input";
import { gradesOptions } from "constants/grades";
import { getAllGamesByType } from "services/games";
import { useState, useRef } from "react";
import AdminRoute from "components/AdminRoute";
import Loading from "components/Shared/Loading";
import { v4 as uuidv4 } from "uuid";
import { saveEvents } from "services/events";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import { gamesOptions } from "constants/games";
import { GameType } from "models/Game";

const useStyles = makeStyles((theme) => ({
  error: {
    textAlign: "left",
    color: "red",
    paddingTop: "5px",
    textTransform: "capitalize",
  },
  inputError: {
    border: "1px solid red",
  },
}));
const fields: FieldType[] = [
  {
    key: "event_name",
    label: "Event Name",
    type: "text",
    maxLength: 24,
  },
  {
    key: "start_date",
    label: "start date",
    type: "date",
    maxLength: 24,
    min: moment().format("YYYY-MM-DD"),
  },
  {
    key: "end_date",
    label: "end date",
    type: "date",
    min: moment().format("YYYY-MM-DD"),
    options: [],
  },
  {
    key: "grade",
    label: "grade",
    type: "multi_select",
    options: gradesOptions,
  },
  {
    key: "game_type",
    label: "game_type",
    type: "select",
    options: [
      {
        label: "--- Please select a game type",
        value: "",
      },
      ...gamesOptions,
    ],
  },
  {
    key: "game_name",
    label: "game_name",
    type: "select",
    options: [],
  },
  {
    key: "points",
    label: "points",
    type: "number",
    maxLength: 6,
  },
];

export default function EventPage() {
  const classes = useStyles();
  const refPoints = useRef<any>();
  const { addToast } = useToasts();
  const [games, setGames] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedGames, setSelectedGames] = useState<
    Record<string, Record<string, string>>
  >({});
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [event, setEvent] = useState<Event>({
    event_name: "",
    start_date: moment().format("YYYY-MM-DD"),
    end_date: "",
    grade: [],
    game_type: "",
    game_name: "",
    points: "",
  });

  const handleCreateSubmit = async () => {
    setIsSubmit(true);
    const isValid = checkIsValid();
    if (!isValid) return;
    const eventGameDetails = Object.values(selectedGames).map((game) => ({
      gameId: game.id,
      points: parseInt(game.points, 10),
    }));
    const eventData = {
      eventId: uuidv4(),
      eventName: event.event_name,
      startDate: event.start_date,
      endDate: event.end_date,
      grade: event.grade,
      eventGameDetails,
    };
    const { data } = await saveEvents(eventData);
    if (data && data.isSuccess) {
      addToast("Event Created Successfully", {
        appearance: "success",
        autoDismiss: true,
      });
    } else {
      addToast(data.errorMessage, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const handleOnChangeGameType = async ({
    gameType,
  }: {
    gameType: GameType;
  }) => {
    if (!gameType) return;
    setLoading(true);
    const data = await getAllGamesByType({ gameType });
    if (data) {
      const game_options = data.map((_) => ({
        label: _.gameName,
        value: _.gameId,
      }));
      if (!game_options.length) {
        alert("This game type doesn't have any game yet");
      }
      setGames([
        { label: "----- Please select a game", value: "" },
        ...game_options,
      ]);
      setLoading(false);
    }
  };

  const changeValue = (e: any, key: string) => {
    if (key === "points") {
      if (e.key === "-") {
        e.target.value = "";
      }
      const number = +e.target.value;
      if (!number || Math.ceil(number) < 0 || isNaN(number)) {
        return;
      }
      const gameType = event.game_type;
      const game = selectedGames[gameType];
      setSelectedGames({
        ...selectedGames,
        [gameType]: {
          ...game,
          points: e.target.value,
        },
      });
    }

    if (key === "game_type" || key === "game_name") {
      setEvent({ ...event, [key]: e.target.value, points: "" });
    } else {
      setEvent({ ...event, [key]: e.target.value });
    }
  };

  const handleOnChangeGame = async (e: any, type: string) => {
    if (!e.target.value) return;
    const game = games.find((g) => g.value === e.target.value) as Option;
    setSelectedGames({
      ...selectedGames,
      [type]: {
        gameName: game.label,
        id: game.value,
        points: "",
      },
    });
    changeValue(e, "game_name");
  };

  const handleChangeSelect = ({ e, key }: { e: any; key: string }) => {
    if (key === "game_type") {
      handleOnChangeGameType({
        gameType: e.target.value,
      });
      changeValue(e, "game_type");
    }
    if (key === "grade") {
      let grade = Array.from(
        e.target.selectedOptions,
        (option: any) => option.value
      );
      setEvent({ ...event, grade });
    }

    if (key === "game_name") {
      handleOnChangeGame(e, event.game_type);
    }
  };

  const checkIsValid = () => {
    return (
      !!event.event_name &&
      !!event.start_date &&
      !!event.end_date &&
      !!event.grade.length &&
      !!Object.keys(selectedGames).length
    );
  };

  return (
    <AdminRoute>
      <Layout>
        <Card onClick={handleCreateSubmit} title="Events">
          <Box mb={3}>
            <Grid container>
              {fields.map(
                (
                  {
                    key: _,
                    type,
                    options,
                    label,
                    input_type,
                    min,
                    max,
                    maxLength,
                  }: FieldType,
                  index: number
                ) => {
                  const key = _ as keyof Event;
                  if (type === "select" || type === "multi_select") {
                    return (
                      <Grid key={index} item xs={12} md={12} lg={12}>
                        <Box mt={1} mb={1}>
                          <Select
                            label={label}
                            onChange={(e: any) =>
                              handleChangeSelect({ e, key })
                            }
                            value={event[key]}
                            name={key}
                            multi={type === "multi_select"}
                            options={
                              key === "game_name" ? games : options || []
                            }
                            inputClassName={
                              isSubmit && !event[key].length
                                ? classes.inputError
                                : ""
                            }
                          />
                        </Box>
                      </Grid>
                    );
                  }
                  return (
                    <Grid item xs={12} md={12} lg={12}>
                      <Box mt={1} mb={1}>
                        <TextField
                          key={index}
                          label={label}
                          ref={key === "points" ? refPoints : null}
                          onChange={(e) => changeValue(e, key)}
                          onKeyDown={(e) => {
                            key === "points" && changeValue(e, key);
                          }}
                          value={event[key]}
                          type={type}
                          inputProps={{
                            maxLength,
                            min: key === "end_date" ? event.start_date : min,
                            max: key === "start_date" ? event.end_date : max,
                          }}
                          name={key}
                          inputClassName={
                            isSubmit && !event[key] ? classes.inputError : ""
                          }
                        />
                        {isSubmit && !event[key] ? (
                          <div className={classes.error}>Requierd</div>
                        ) : null}
                      </Box>
                    </Grid>
                  );
                }
              )}
              {Object.entries(selectedGames).map((game) => {
                return (
                  <>
                    {game[1].gameName && (
                      <Chip
                        label={`${game[1].gameName} ${
                          game[1].points ? `(${game[1].points})` : ""
                        }`}
                        key={game[1].id}
                        onDelete={() => {
                          const gameType = game[0];
                          var clone = Object.assign({}, selectedGames);
                          delete clone[gameType];
                          setSelectedGames(clone);
                        }}
                      />
                    )}
                  </>
                );
              })}
            </Grid>
          </Box>
        </Card>
        <Loading open={loading} />
      </Layout>
    </AdminRoute>
  );
}
