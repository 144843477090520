import Game from "./Game";
import { makeStyles, Grid, Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 30,
  },
  title: {
    width: "100%",
    color: "#3f72af",
    marginTop: 20,
    marginBottom: 20,
    textAlign: "center",
    fontSize: "2.5rem",
    fontWeight: 500,
  },
  label: {
    width: "100%",
    textAlign: "center",
    fontSize: 20,
    textDecoration: "none",
  },
  icon: {
    borderRadius: "50%",
    padding: 30,
    width: "70% !important",
    height: "100%",
    transition: ".8s all",
    marginBottom: 20,
    border: `1px solid #e93b81`,
    "&:hover": {
      color: "#e93b81",
      background: "#FFFFFF",
      borderColor: "#e93b81",
      borderWidth: 1,
    },
  },
  icon1: {
    backgroundColor: "blue",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      color: "blue",
    },
  },
}));

export default function Games({ games, event }: { games: any; event: any }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>Welcome to Games Page</h2>
      <Container>
        <Grid container spacing={3}>
          {games.map((game: any, index: number) => (
            <Grid key={index} item md={4} sm={12} xs={12}>
              <Game {...game} event={event} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}
