import { useState, useEffect, useContext } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import Layout from "components/Layout";
import { createStyles, makeStyles, Snackbar } from "@material-ui/core";
import styled from "styled-components";
import { Card } from "components/Shared";
import AuthContext from "contexts/AuthContext";
import UserRoute from "components/UserRoute";
import useGame from "hooks/useGame";
import { useLocation } from "react-router-dom";
import Loader from "components/Loader";
import { updateGamePoints } from "services/games";
import Alert from "@material-ui/lab/Alert";

const WordDiv = styled.div`
  @media (max-width: 768px) {
    width: 95%;
  }
  width: 250px;
  border-radius: 5px;
  color: #3498db;
  font-weight: bold;
  font-size: 18px;
  padding: 10px 20px;
  margin: 10px 5px;
  background-color: #dcebf3;
  border: 1px solid #ebeded;
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 10px;
`;
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#f9fbfb",
      borderRadius: "10px",
      border: "1px solid #ebeded",
      padding: "20px",
      fontFamily: "'Bitter', serif",
      display: "flex",
      flexDirection: "column",
      width: "80%",
      margin: "auto",
      justifyContent: "center",
      alignItems: "center",
    },
    word: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      width: "100%",
    },
    wordList: {
      display: "flex",
      flexDirection: "column",
      width: "50%",
      alignItems: "center",
    },
    subTitle: {
      fontXize: "19px",
      color: "#112d4e",
    },
  })
);

export default function Matching(): JSX.Element {
  const { verify: getCurrentUser } = useContext(AuthContext);

  const classes = useStyles();
  const {
    state: { id, points, event },
  } = useLocation() as any;
  const { game, loading } = useGame({ id });
  const gamesDetails = JSON.parse(game ? game.gamesDetails : "[]");
  const gamesSolution = JSON.parse(game ? game.gamesSolution : "[]");
  const [answers, setAnswers] = useState<string[]>([]);
  const [words, setWords] = useState<string[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const wordsTemp = gamesDetails.map((g: any) => g.word);
    const values = gamesDetails
      .map((a: any) => ({ sort: Math.random(), value: a.answer }))
      .sort((a: any, b: any) => a.sort - b.sort)
      .map((g: any) => g.value);
    setWords(wordsTemp);
    setAnswers(values);

    // eslint-disable-next-line
  }, [loading]);

  const reorder = (from: number, to: number) => {
    const result = Array.from(answers);
    const [removed] = result.splice(from, 1);
    result.splice(to, 0, removed);
    return result;
  };

  const onDragEnd = (results: DropResult) => {
    if (!results.destination) {
      return;
    }
    const new_answers = reorder(
      results.source.index,
      results.destination.index
    );
    setAnswers(new_answers);
  };

  const handleSubmit = async () => {
    const correctAnswers = gamesSolution.filter(
      ({ answer }: any, index: number) => answer === answers[index]
    );
    const score = Math.ceil(correctAnswers.length * (points / words.length));
    const user = await getCurrentUser();
    const res = await updateGamePoints({
      gameId: id,
      points: score,
      eventGradeId: event.eventSimplified.eventGradeId,
      eventId: event.eventSimplified.eventId,
      profileId: user.profileId,
    });
    if (res) {
      setOpen(true);
    }
  };
  return (
    <UserRoute>
      <Layout>
        <Card
          onClick={handleSubmit}
          title={game ? game.gameName : ""}
          className={classes.root}
        >
          <p className={classes.subTitle}>
            Arrange the defintion for each word to its respective defintion
          </p>
          <div className={classes.word}>
            <div className={classes.wordList}>
              <Title>Words</Title>
              {words.map((item: any, index: number) => (
                <WordDiv key={item}>
                  <p>{item}</p>
                </WordDiv>
              ))}
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(droppableProvided) => (
                  <div
                    className={classes.wordList}
                    ref={droppableProvided.innerRef}
                  >
                    <Title>Definitions</Title>

                    {answers.map((item: any, index: number) => (
                      <Draggable key={item} draggableId={item} index={index}>
                        {(draggableProvided) => (
                          <WordDiv
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <p> {item}</p>
                          </WordDiv>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Card>
        <Loader isOpen={loading} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          onClose={() => setOpen(false)}
          key={"top right"}
        >
          <Alert onClose={() => setOpen(false)} severity="success">
            Your score has been saved
          </Alert>
        </Snackbar>
      </Layout>
    </UserRoute>
  );
}
