import React from "react";
import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    blank: {
      margin: "0 8px",
      border: 0,
      background: "inherit",
      borderBottom: "2px dashed #3498DB",
      fontFamily: "inherit",
      fontSize: "15px",
      maxWidth: "160px",
      textAlign: "center",
      color: "#3498DB",
    },
  })
);

export default function DropZone({
  id,
  selected,
  answerArray,
  setAnswerArray,
  answers,
  dragged,
  setDragged,
}: {
  id: number;
  selected: string;
  answerArray: string[];
  setAnswerArray: (answerArray: string[]) => void;
  answers: string[];
  dragged: boolean[];
  setDragged: (dragged: boolean[]) => void;
}): JSX.Element {
  const classes = useStyles();

  const dragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const dragEnter = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const dragLeave = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const answerDrop = (e: React.DragEvent<HTMLInputElement>) => {
    if (answerArray) {
      let tempArray = [...answerArray];
      let res = tempArray.findIndex((a) => a === selected);
      if (res !== -1) tempArray[res] = "";
      tempArray[id] = selected;
      setAnswerArray(tempArray);
      let tempDragged = [...dragged];
      let index = answers.findIndex((a) => a === selected);
      tempDragged[index] = false;
      setDragged(tempDragged);
    }
  };
  return (
    <>
      <input
        key={id}
        className={classes.blank}
        name={id.toString()}
        value={answerArray ? answerArray[id] : ""}
        type="text"
        readOnly={true}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={answerDrop}
      ></input>
    </>
  );
}
