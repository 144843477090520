import Layout from "components/Layout";
import MainImage from "components/MainImage";
import Events from "components/Events";
import UserRoute from "components/UserRoute";
import useActiveEvent from "hooks/useActiveEvent";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
import Loading from "components/Shared/Loading";

export default function Home() {
  const { events, loading } = useActiveEvent();
  const mapEventsToLinks = (event: any) => {
    return {
      event,
      label: `${event.eventSimplified.eventName}`,
      image: faGamepad,
      color: "#29bb89",
    };
  };
  return (
    <UserRoute>
      <Layout>
        <MainImage />
        <Events events={events.map(mapEventsToLinks)} />
        <Loading open={loading} />
      </Layout>
    </UserRoute>
  );
}
