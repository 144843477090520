import { useEffect, useState } from "react";
import { getAllChurches } from "services/churches";

const useChurches = () => {
  const [loading, setLoading] = useState(true);
  const [churches, setChurches] = useState<string[] | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const {data} = await getAllChurches();
        setChurches(data.churches);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    churches,
  };
};

export default useChurches;
