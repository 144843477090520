import { useEffect } from "react";
import * as msal from "msal";
import { msalAppConfig, scopes } from "../../msalAppConfig";
import Loading from "components/Shared/Loading";
import { getProfileByEmail } from "services/auth";
import { useHistory } from "react-router-dom";

/**
 * Roles 
 * ======= 
 * 1 : User
 * 2 : Servant
 * 3 : Admin
 */


const Login = () => {
  const history = useHistory();

  useEffect(() => {
    const redirectUser = async (email: string) => {
      const res = await getProfileByEmail(email);

      if (!res.data.isSuccess) {
        history.push("/");
      }
      if (!res.data.role) {
        history.push("/register");
      } else {
        if (res.data.role !== 1) {
          history.push("/admin");
        } else {
          history.push("/home");
        }
      }
    };

    const msalApp = new msal.UserAgentApplication(msalAppConfig);
    const account = msalApp.getAccount();
    if (!account) {
      msalApp.loginRedirect(scopes);
    } else {
      redirectUser(account.idToken.emails[0]);
    }
  }, [history]);

  return <Loading open />;
};

export default Login;
