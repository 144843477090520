import {
  faSearch,
  faList,
  faFileSignature,
  faChurch,
} from "@fortawesome/free-solid-svg-icons";
import { GiMaze } from "react-icons/gi";
import { BsFillFileWordFill } from "react-icons/bs";
import { GameType } from "models/Game";

export const gamesConfig = {
  WordSearch: {
    link: "/games/word-search/",
    image: faSearch,
    color: "#0dcaf0",
  },
  Coptic: {
    link: "/games/coptic",
    image: faChurch,
    color: "#aa2ee6",
  },
  matching: {
    link: "/games/matching/",
    image: faList,
    color: "#29bb89",
  },
  FillTheBlank: {
    link: "/games/fill-blanks/",
    image: faFileSignature,
    color: "#FF5DA2",
  },
  maze: {
    link: "/games/maze/",
    type: "maze",
    image: faSearch,
    color: "#e17bd9",
    Externalicon: (
      <GiMaze
        color="#fff"
        style={{ backgroundColor: "#00d1b2", border: "1px solid #00d1b2" }}
      />
    ),
  },
  scramble: {
    link: "/games/word-scramble/",
    type: "scramble",
    image: faChurch,
    color: "red",
    Externalicon: (
      <BsFillFileWordFill
        color="#fff"
        style={{ backgroundColor: "#e93b81", border: "1px solid #e93b81" }}
      />
    ),
  },
};

export const gamesOptions: { label: string; value: GameType }[] = [
  {
    label: "Fill The Blank",
    value: "FillTheBlank",
  },
  {
    label: "Matching List",
    value: "matching",
  },
  {
    label: "Word Scramble",
    value: "scramble",
  },
  {
    label: "Maze",
    value: "maze",
  },
  {
    label: "Coptic",
    value: "Coptic",
  },
];
