import { useEffect, useState } from "react";
import { Event } from "models/Event";
import { getAllEvents } from "services/events";

const useEvents = ({ id = "" }) => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState<Event[]>([]);
  const [oneEvent, setOneEvent] = useState<any | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getAllEvents();
        setEvents(data.events);
        if (id) {
          const event = data.events.find(({ eventId }: any) => eventId === id);
          setOneEvent(event);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    events,
    oneEvent,
  };
};

export default useEvents;
