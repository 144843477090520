import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { Box, Grid, Snackbar } from "@material-ui/core";
import Alert, { Color } from "@material-ui/lab/Alert";
import { Check } from "@material-ui/icons";

import Layout from "components/Layout";
import Loader from "components/Loader";

import { TextField, Card } from "components/Shared";
import AdminRoute from "components/AdminRoute";

const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const GridItem = styled(Grid)`
  text-align: center;
  font-size: 24px;
  color: #112d4e;
  font-weight: 600;
`;

const StyledFile = styled.input`
  padding: 4px 0 4px 10px;
  margin-bottom: 15px;
  border: 1px solid #98c4e1;
  height: 38px;
  font-size: 16px;
  -moz-box-shadow: 2px 2px 4px 0px #98c4e1;
  -webkit-box-shadow: 2px 2px 4px 0px #98c4e1;
  box-shadow: 2px 2px 4px 0px #98c4e1;
  -moz-border-radius: 7px 7px 7px 7px;
  -webkit-border-radius: 7px 7px 7px 7px;
  border-radius: 7px 7px 7px 7px;
  background-color: #fff;
  width: 100%;
  cursor: pointer;
`;

const StyledOption = styled(TextField)`
  width: 100px;
  margin-right: 10px;
`;

type Question = {
  audio: string;
  option0: string;
  option1: string;
  option2: string;
  option3: string;
  answer: string;
};

const questions: Question[] = [
  {
    audio: "",
    option0: "",
    option1: "",
    option2: "",
    option3: "",
    answer: "",
  },
];

interface ErrorStatus {
  status?: Color;
  message?: string;
}

export default function CopticServant(): JSX.Element {
  const [options, setOptions] = useState(questions);
  const [errorStatus, setErrorStatus] = useState<ErrorStatus>({});
  const [open, setOpen] = useState(false);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);

  return (
    <AdminRoute>
      <Layout>
        <Loader isOpen={isLoaderOpen} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          onClose={() => setOpen(false)}
          key={"top right"}
        >
          <Alert onClose={() => setOpen(false)} severity={errorStatus.status}>
            {errorStatus.message}
          </Alert>
        </Snackbar>
        <Formik
          initialValues={{
            gameName: "",
            numberOfAudioWords: 1,
            questions: options,
          }}
          onSubmit={(values) => {
            setIsLoaderOpen(true);
            if (
              !(
                values.gameName === "" ||
                values.questions.some(
                  (value: any) =>
                    value.audio === "" ||
                    value.question0 === "" ||
                    value.question1 === "" ||
                    value.question2 === "" ||
                    value.question3 === "" ||
                    value.answer === ""
                )
              )
            ) {
              const storeGame = {
                isSuccess: true,
                errorMessage: "string",
                gameId: uuidv4(),
                gameName: values.gameName,
                gameType: "Coptic",
                gamesDetails: JSON.stringify(values),
                gamesSolution: "string",
              };
              return axios
                .post(
                  "https://gamesfunctionapp.azurewebsites.net/api/V2/Games/StoreGame",
                  storeGame
                )
                .then((response) => {
                  setErrorStatus({
                    status: response.data.isSuccess ? "success" : "error",
                    message: response.data.isSuccess
                      ? "Game has been submitted successfully!"
                      : response.data.errorMessage,
                  });
                  setOpen(true);
                  setIsLoaderOpen(false);
                })
                .catch((error) => console.log(error));
            } else {
              setErrorStatus({
                status: "error",
                message: "Please fill all fileds before submitting",
              });
              setOpen(true);
              setIsLoaderOpen(false);
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              <Card onClick={handleSubmit} title="Learn Coptic">
                <Box mb={2}>
                  <TextField
                    key={1}
                    label="Game Name"
                    value={values.gameName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="gameName"
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    key={2}
                    type="number"
                    label="Number Of Audio Words"
                    value={values.numberOfAudioWords}
                    inputProps={{
                      min: 0,
                      max: 10,
                      maxLength: 2,
                    }}
                    onChange={(event: {
                      target: HTMLTextAreaElement | HTMLInputElement;
                    }) => {
                      setOptions(
                        Array<Question>(Number(event.target.value)).fill(
                          questions[0]
                        )
                      );
                      if (event.target.value.length >= 2) {
                        return;
                      }

                      handleChange(event);
                    }}
                    onBlur={handleBlur}
                    name="numberOfAudioWords"
                  />
                </Box>
                <hr />
                <Grid container spacing={3}>
                  <GridItem item xs={3}>
                    File
                  </GridItem>
                  <GridItem item xs={6}>
                    Question Options
                  </GridItem>
                  <GridItem item xs={3}>
                    Answer
                  </GridItem>
                </Grid>

                {options.map((x, index) => {
                  return (
                    <Grid container spacing={3}>
                      <GridItem item xs={3}>
                        {values.questions[index]?.audio && <Check />}
                        <StyledFile
                          type="file"
                          accept="audio/*"
                          onChange={(e: any) => {
                            setIsLoaderOpen(true);
                            const formData = new FormData();
                            formData.append("File", e.target.files[0]);
                            formData.append(
                              "Description",
                              e.target.files[0].name
                            );
                            return axios
                              .post(
                                "https://gamesfunctionapp.azurewebsites.net/api/V2/Media/UploadMedia",
                                formData
                              )
                              .then((response) => {
                                setOpen(true);
                                if (response.data.isSuccess) {
                                  setFieldValue(
                                    `questions[${index}].audio`,
                                    e.target.files[0].name
                                  );
                                }
                                setErrorStatus({
                                  status: response.data.isSuccess
                                    ? "success"
                                    : "error",
                                  message: response.data.isSuccess
                                    ? "File uploaded successfully"
                                    : "Error uploading the file",
                                });
                                setIsLoaderOpen(false);
                              })
                              .catch((error) => console.log(error));
                          }}
                        />
                        <input
                          type="hidden"
                          key={`question-${index}`}
                          name={`questions[${index}].audio`}
                          value={values.questions[index]?.audio}
                        />
                      </GridItem>
                      <GridItem item xs={6}>
                        <StyledOption
                          key={`option-0-${index}`}
                          label=""
                          placeholder={`Option 1`}
                          value={`${values.questions[index]?.option0 || ""}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={`questions[${index}].option0`}
                        />
                        <StyledOption
                          key={`option-1-${index}`}
                          label=""
                          placeholder={`Option 2`}
                          value={`${values.questions[index]?.option1 || ""}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={`questions[${index}].option1`}
                        />
                        <StyledOption
                          key={`option-2-${index}`}
                          label=""
                          placeholder={`Option 3`}
                          value={`${values.questions[index]?.option2 || ""}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={`questions[${index}].option2`}
                        />
                        <StyledOption
                          key={`option-3-${index}`}
                          label=""
                          placeholder={`Option 4`}
                          value={`${values.questions[index]?.option3 || ""}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={`questions[${index}].option3`}
                        />
                      </GridItem>
                      <GridItem item xs={3}>
                        <StyledOption
                          key={`answer-${index}`}
                          label=""
                          placeholder="Answer"
                          value={`${values.questions[index]?.answer || ""}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name={`questions[${index}].answer`}
                        />
                      </GridItem>
                    </Grid>
                  );
                })}
              </Card>
            </Form>
          )}
        </Formik>
      </Layout>
    </AdminRoute>
  );
}
