import React, { useState, useRef } from "react";
import Layout from "components/Layout";
import { Card, createStyles, makeStyles, Theme } from "@material-ui/core";
import { addGame } from "services/games";
import { Game } from "models/Game";
import { v4 as uuidv4 } from "uuid";
import { useToasts } from "react-toast-notifications";
import AdminRoute from "components/AdminRoute";
import _ from "lodash"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#edf6f6",
      margin: "3% auto",
      borderRadius: "10px",
      border: "1px solid #ebeded",
      padding: "20px 40px",
      fontFamily: "'Bitter', serif",
      textAlign: "left",
      width: "47%",
      [theme.breakpoints.down("md")]: {
        width: "90%",
      },
    },
    divider: {
      width: "100%",
      margin: "20px auto",
    },
    blank: {
      color: "#3498DB",
      fontWeight: "bold",
      padding: "0 8px",
      fontSize: "unset",
    },

    submit: {
      color: "#fff",
      backgroundColor: "#3498DB",
      borderColor: "#3498DB",
      fontWeight: 400,
      boxSizing: "border-box",
      lineHeight: 1.5,
      textAlign: "center",
      display: "block",
      fontSize: "20px",
      borderRadius: "5px",
      transition: "all 0.5s ease",
      margin: "auto",
      marginTop: "20px",
      padding: "8px 25px",
      cursor: "pointer",
    },

    labels: {
      color: "#3f72af",
      fontSize: 18,
      fontWeight: 500,
    },
    inputs: {
      width: "100%",
      color: "#999",
      fontWeight: 500,
      fontSize: 18,
      height: 40,
      border: "1px solid #3498DB",
      display: "block",
      padding: "0.375rem 0.75rem",
      background: "#fff",
      borderRadius: ".25rem",
    },
    list: {
      textAlign: "left",
      padding: "10px 20px",
      overflow: "hidden",
      listStyle: "none",
      display: "grid",
      gridTemplateColumns: "auto auto",
    },
    listItem: {
      marginBottom: "17px",
      fontSize: "20px",
    },
    error: {
      color: "#b00505",
      marginBottom: "25px",
      textAlign: "end",
    },
  })
);

export default function WordScramble(): JSX.Element {
  const { addToast } = useToasts();
  const numberRef = useRef<any>();

  const classes = useStyles();
  const [numberOfWords, setNumberOfWords] = useState<number>(0);
  const [gameData, setGameData] = useState<string[]>([]);
  const [gameName, setGameName] = useState<string>("");
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const handleNumberChange = (e: any) => {
    if (e.key === "-") {
      numberRef.current.value = 1;
    }
    const number = +e.target.value;
    if (
      !number ||
      Math.ceil(number) < 0 ||
      Math.ceil(number) > 15 ||
      isNaN(number)
    ) {
      return;
    }
    setNumberOfWords(Math.ceil(number));
    setGameData(new Array(Math.ceil(number)).fill(""));
  };

  const handleGameNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGameName(e.target.value);
  };
  const handleWordChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newData = gameData.map((d, i) => (i === index ? e.target.value : d));
    setGameData(newData);
  };

  const checkIfIsValid = () => {
    const isValid = gameData.every((d) => d);
    return isValid && !!gameName && !!numberOfWords;
  };

  const handleSubmit = async () => {
    setIsSubmit(true);
    const isValid = checkIfIsValid();
    if (!isValid) return;
    const newGame: Game = {
      gameId: uuidv4(),
      gameName: gameName,
      gameType: "scramble",
      gamesDetails: JSON.stringify(
        gameData.map((word) =>  _.shuffle( word
            .split("")).join("")
        
         
            
        )
      ),
      gamesSolution: JSON.stringify(gameData),
    };

    const { data } = await addGame(newGame);
    if (data.isSuccess) {
      addToast("Game Created Successfully", {
        appearance: "success",
        autoDismiss: true,
      });
    } else {
      addToast(data.errorMessage, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <AdminRoute>
      <Layout>
        <Card className={classes.root}>
          <label className={classes.labels} htmlFor="gameName">
            Game Name
          </label>
          <input
            className={classes.inputs}
            id="gameName"
            value={gameName}
            onChange={handleGameNameChange}
            placeholder="Game Name"
            style={{
              marginBottom: isSubmit && !gameName ? 0 : 25,
              borderColor: isSubmit && !gameName ? "red" : "",
            }}
          />
          {isSubmit && !gameName ? (
            <div className={classes.error}>Game's name is required</div>
          ) : null}
          <label className={classes.labels} htmlFor="wordNumber">
            Number of Word scramble
          </label>
          <input
            className={classes.inputs}
            type="number"
            value={numberOfWords ? numberOfWords : ""}
            onChange={handleNumberChange}
            id="wordNumber"
            ref={numberRef}
            onKeyDown={handleNumberChange}
            max={15}
            placeholder="Enter Number of Words"
            style={{
              marginBottom: isSubmit && !numberOfWords ? 0 : 25,
              borderColor: isSubmit && !numberOfWords ? "red" : "",
            }}
          />
          {isSubmit && !numberOfWords ? (
            <div className={classes.error}>Number Of Words is required</div>
          ) : null}
          <hr className={classes.divider} />
          {gameData.map((word, index) => (
            <>
              <input
                className={classes.inputs}
                key={index}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleWordChange(index, e)
                }
                value={word}
                placeholder="Enter Word Here"
                style={{
                  marginBottom: isSubmit && !word ? 0 : 25,
                  borderColor: isSubmit && !word ? "red" : "",
                }}
              />
              {isSubmit && !word ? (
                <div className={classes.error}>This word is required</div>
              ) : null}
            </>
          ))}
          <button className={classes.submit} onClick={handleSubmit}>
            Submit
          </button>
        </Card>
      </Layout>
    </AdminRoute>
  );
}
