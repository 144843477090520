import { useEffect, useState } from "react";
import { getMediaUrl } from "services/media";

const useMazeMedia = ({ gameDetails }: { gameDetails: any }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>({ background: "", character: "" });

  useEffect(() => {
    if (!gameDetails) return;
    (async () => {
      try {
        const details = JSON.parse(gameDetails);
        const { data: background } = await getMediaUrl({
          fileName: details.background,
        });
        const { data: character } = await getMediaUrl({
          fileName: details.character,
        });
        setData({ background, character });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameDetails]);

  return {
    ...data,
    loading,
  };
};

export default useMazeMedia;
