import React, { useState } from "react";
import Layout from "components/Layout";
import {
  Card,
  CardContent,
  Grid,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { addGame } from "services/games";
import { Game } from "models/Game";
import { v4 as uuidv4 } from "uuid";
import { useToasts } from "react-toast-notifications";
import AdminRoute from "components/AdminRoute";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#edf6f6",
      margin: "3% auto",
      borderRadius: "10px",
      border: "1px solid #ebeded",
      padding: "20px 40px",
      fontFamily: "'Bitter', serif",
      textAlign: "left",
      width: "75%",
    },
    divider: {
      width: "100%",
      margin: "20px auto",
    },
    blank: {
      color: "#3498DB",
      fontWeight: "bold",
      padding: "0 8px",
      fontSize: "unset",
    },
    content: {
      width: "100%",
      color: "#4d5053",
      padding: "10px 20px",
      lineHeight: "42px",
      fontSize: "19px",
      fontFamily: "'Bitter', serif",
      marginTop: 0,
      resize: "none",
      border: "1px solid #3498DB",
      borderRadius: ".25rem",
    },
    inputContainer: {
      margin: "0 20px",
    },
    header: {
      textAlign: "center",
      padding: "10px",
      margin: 0,
      color: "#3f72af",
      fontSize: "30px",
      fontWeight: 600,
    },
    answerHeader: {
      textAlign: "center",
      padding: "10px",
      color: "#3f72af",
      fontSize: "30px",
      fontWeight: 600,
    },

    container: {
      textAlign: "center",
    },
    submit: {
      color: "#fff",
      backgroundColor: "#3498DB",
      borderColor: "#3498DB",
      fontWeight: 400,
      boxSizing: "border-box",
      lineHeight: 1.5,
      textAlign: "center",
      display: "block",
      fontSize: "20px",
      borderRadius: "5px",
      transition: "all 0.5s ease",
      margin: "20px auto",
      padding: "8px 25px",
      cursor: "pointer",
    },
    button: {
      display: "inline-block",
      minHeight: "50px",
      padding: "10px 10px 10px 25px",
      fontSize: "17px",
      color: "#3498DB",
      backgroundColor: "#dcebf3",
      border: "1px solid #ebeded",
      borderRadius: "5px",
      whiteSpace: "nowrap",
      fontWeight: "bold",
      width: "90%",
    },
    labels: {
      color: "#3f72af",
      fontSize: 18,
      fontWeight: 500,
    },
    inputs: {
      width: "100%",
      marginBottom: "10px",
      color: "#999",
      fontWeight: 500,
      fontSize: 18,
      height: 40,
      border: "1px solid #3498DB",
      display: "block",
      padding: "0.375rem 0.75rem",
      background: "#fff",
      borderRadius: ".25rem",
    },
    error: {
      color: "#b00505",
    },
    number: {
      position: "absolute",
    },
  })
);

export default function FillBlancks(): JSX.Element {
  const { addToast } = useToasts();

  const classes = useStyles();
  const [numberOfWords, setNumberOfWords] = useState<number>(1);
  const [paragraph, setParagraph] = useState<string>("");
  const [gameName, setGameName] = useState<string>("");
  const [answers, setAnswers] = useState<string[]>([""]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = +e.target.value;
    if (!number || Math.ceil(number) < 0 || Math.ceil(number) > 15) {
      return;
    }
    setAnswers(new Array(Math.ceil(number)).fill(""));
    setNumberOfWords(Math.ceil(number));
    setIsSubmit(false);
  };

  const handleParagraphChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const missing_words = e.target.value.match(/\{[a-zA-z ]*\}/g);
    const temp = [...answers];
    missing_words?.forEach((word, index) => {
      temp[index] = word.substr(1, word.length - 2);
    });
    setAnswers(temp);
    setParagraph(e.target.value);
  };
  const handleGameNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGameName(e.target.value);
  };
  const handleAnswersChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const temp = answers;
    temp[index] = e.target.value;
    setAnswers([...temp]);
  };

  const getAnswers = () => {
    return (paragraph.match(/\[[ 0-9]+]/g) ?? []).map((a) =>
      parseInt(a.replace(/\D/g, ""))
    );
  };

  const checkIfIsValid = () => {
    const isValid = answers.every((d) => d);
    return (
      isValid &&
      !!gameName &&
      !!numberOfWords &&
      getAnswers().length === answers.length
    );
  };

  const handleSubmit = async () => {
    setIsSubmit(true);
    const isValid = checkIfIsValid();
    if (!isValid || validateParagraph()) return;
    const newGame: Game = {
      gameId: uuidv4(),
      gameName: gameName,
      gameType: "FillTheBlank",
      gamesDetails: JSON.stringify(paragraph),
      gamesSolution: JSON.stringify(answers),
    };
    const { data } = await addGame(newGame);
    if (data.isSuccess) {
      addToast("Game Created Successfully", {
        appearance: "success",
        autoDismiss: true,
      });
    } else {
      addToast(data.errorMessage, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const validateParagraph = () => {
    const sameNumberOfAnswers = getAnswers().length === answers.length;
    const hasDuplicatedValue = getAnswers().filter(
      (item, pos) => getAnswers().indexOf(item) === pos
    );
    const isSameOrder = getAnswers().every(
      (val, i, arr) => !i || val >= arr[i - 1]
    ); // sort
    const hasZero = getAnswers().some((a) => a === 0);
    const missingNumber = getAnswers().some((n, i) => n !== i + 1);

    if (!sameNumberOfAnswers) {
      return `The number of words you inserted are not equals to the the squire bracket "[]" you inserted here`;
    }
    if (hasDuplicatedValue.length !== answers.length) {
      return "Please remove duplicated numbers ";
    }

    if (!isSameOrder) {
      return `You insert squire bracket "[]" in the incorrect order `;
    }

    if (missingNumber) {
      return `numbers aren't in order `;
    }
    if (hasZero) {
      return `You can't insert zero  `;
    }
    return false;
  };

  return (
    <AdminRoute>
      <Layout>
        <Card className={classes.root}>
          <div className={classes.inputContainer}>
            <label className={classes.labels} htmlFor="gameName">
              Game Name
            </label>
            <input
              className={classes.inputs}
              id="gameName"
              value={gameName}
              onChange={handleGameNameChange}
              placeholder="Game Name"
              maxLength={20}
              style={{
                marginBottom: isSubmit && !gameName ? 25 : 0,
                borderColor: isSubmit && !gameName ? "red" : "",
              }}
            />
            {isSubmit && !gameName ? (
              <div className={classes.error}>Required</div>
            ) : null}
            <label className={classes.labels} htmlFor="wordNumber">
              Number of Fill Words
            </label>
            <input
              className={classes.inputs}
              style={!numberOfWords ? { borderColor: "#b00505" } : {}}
              type="number"
              value={numberOfWords}
              onChange={handleNumberChange}
              id="wordNumber"
              placeholder="9"
            />
          </div>
          <hr className={classes.divider} />
          <div className={classes.inputContainer}>
            <h2 className={classes.header}>
              {gameName ? gameName : "Game Name"}
            </h2>
            <textarea
              className={classes.content}
              id="outlined-multiline-static"
              onChange={handleParagraphChange}
              style={{
                marginBottom:
                  isSubmit && getAnswers().length !== answers.length ? 25 : 0,
                borderColor:
                  isSubmit && getAnswers().length !== answers.length
                    ? "red"
                    : "",
              }}
            />
            {isSubmit && validateParagraph() ? (
              <p className={classes.error}>{validateParagraph()}</p>
            ) : null}
          </div>
          <p style={{ whiteSpace: "pre-line" }}>
            {`example of the game : 
              number of fill words :2 
              body here will be like:
              "My name is [1] and I am [2] years old"`}
          </p>
        </Card>

        <Card className={classes.root}>
          <h2 className={classes.header}>Solutions</h2>

          <CardContent>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              {answers
                ? answers.map((answer, index) => {
                    return (
                      <Grid item xs={6} key={index}>
                        <span className={classes.number}>{`[${
                          index + 1
                        }]`}</span>
                        <input
                          className={classes.button}
                          value={answer}
                          onChange={(e) => handleAnswersChange(e, index)}
                          style={{
                            borderColor: isSubmit && !answer ? "red" : "",
                          }}
                        />
                        {isSubmit && !answer ? (
                          <p className={classes.error}>Required</p>
                        ) : null}
                      </Grid>
                    );
                  })
                : null}
            </Grid>
          </CardContent>
        </Card>
        <button className={classes.submit} onClick={handleSubmit}>
          Submit
        </button>
      </Layout>
    </AdminRoute>
  );
}
