import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Container } from "@material-ui/core";

import Link from "./Link";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 30,
  },
  title: {
    width: "100%",
    color: "#3f72af",
    marginTop: 20,
    marginBottom: 20,
    textAlign: "center",
    fontSize: "2.5rem",
    fontWeight: 500,
  },
  label: {
    width: "100%",
    textAlign: "center",
    fontSize: 20,
  },
}));

export default function Links({ links }: { links: any }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h2 className={classes.title}>Welcome to The Coptic Way</h2>
      <Container>
        <Grid container spacing={3}>
          {links.map((link: any, index: number) => (
            <Grid key={index} item md={4} sm={12} xs={12}>
              <Link {...link} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}
