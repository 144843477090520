import {
  InputBase,
  FormControl,
  withStyles,
  alpha,
  makeStyles,
  InputBaseProps,
} from "@material-ui/core";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(0),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    // border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);
const useStyles = makeStyles((theme) => ({
  margin: {
    width: "100%",
  },
  label: {
    textTransform: "capitalize",
    color: "#443e3e",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
  },
}));

const TextField = ({
  label,
  onChange,
  type,
  onBlur,
  value,
  name,
  input_type,
  error,
  className,
  inputProps,
  placeholder,
  inputClassName,
  ref,
  ...props
}: InputBaseProps & {
  input_type?: string;
  label?: string;
  inputClassName?:string
}) => {
  const classes = useStyles();
  return (
    <FormControl className={`${className} ${classes.margin}`}>
      {label && (
        <label className={classes.label}>{label.replaceAll("_", " ")}</label>
      )}
      <BootstrapInput
        defaultValue={value}
        onChange={onChange}
        type={input_type ?? type}
        name={name}
        onBlur={onBlur}
        style={{ border: error ? "1px solid red" : "" }}
        value={value}
        className={inputClassName}
        inputProps={inputProps}
        placeholder={placeholder}
        ref={ref}
        {...props}
      />
    </FormControl>
  );
};

export default TextField;
