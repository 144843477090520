import React from "react";
import { Button, makeStyles, ButtonProps } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    color: "#FFFFFF",
    width: "15%",
    backgroundColor: "#3498DB",
    fontSize: 18,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      borderColor: "#3498DB",
      color: "#3498DB",
      borderWidth: 1,
      border: "solid",
    },
  },
}));

const ButtonCom = ({
  text,
  onClick,
  ...props
}: { text: string; onClick?: any } & ButtonProps) => {
  const classes = useStyles();
  return (
    <Button className={classes.button} onClick={onClick} {...props}>
      {text}
    </Button>
  );
};

export default ButtonCom;
