import { Box, Grid } from "@material-ui/core";
import Layout from "components/Layout";
import { Formik, Form } from "formik";
import { TextField, Card, Select } from "components/Shared";
import { FieldType } from "models/Input";
import AdminRoute from "components/AdminRoute";

const game_details: FieldType[] = [
  {
    key: "game_details_type",
    label: "Game Details Type",
    type: "text",
  },
];
export default function GameDetailsCard() {
  return (
    <AdminRoute>
      <Layout>
        <Card onClick={() => {}} title="Game Details">
          <Box mb={3}>
            <Formik
              initialValues={{
                game_details_type: "",
              }}
              onSubmit={() => {}}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form>
                  <Grid container>
                    {game_details.map(
                      (
                        { key, type, options, label, input_type }: FieldType,
                        index: number
                      ) => {
                        switch (type) {
                          case "text":
                            return (
                              <Grid item xs={12} md={12} lg={12}>
                                <Box mt={1} mb={1}>
                                  <TextField
                                    key={index}
                                    label={label}
                                    onChange={handleChange}
                                    value={values.game_details_type}
                                    onBlur={handleBlur}
                                    name={key}
                                    input_type={input_type}
                                  />
                                </Box>
                              </Grid>
                            );
                          case "select":
                            return (
                              <Grid item xs={12} md={12} lg={12}>
                                <Box mt={1} mb={1}>
                                  <Select
                                    key={index}
                                    label={label}
                                    onChange={handleChange}
                                    value={values.game_details_type}
                                    onBlur={handleBlur}
                                    name={key}
                                    options={options ?? []}
                                  />
                                </Box>
                              </Grid>
                            );
                          default:
                            return <></>;
                        }
                      }
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Card>
      </Layout>
    </AdminRoute>
  );
}
