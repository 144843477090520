import { Grade } from "models/Grade";

export const gradesOptions: { label: string; value: Grade }[] = [
  {
    label: "Grade 1",
    value: "grade 1",
  },
  {
    label: "Grade 2",
    value: "grade 2",
  },
  {
    label: "Grade 3",
    value: "grade 3",
  },
  {
    label: "Grade 4",
    value: "grade 4",
  },
  {
    label: "Grade 5",
    value: "grade 5",
  },
  {
    label: "Grade 6",
    value: "grade 6",
  },
  {
    label: "Grade 7",
    value: "grade 7",
  },
];
