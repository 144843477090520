import Layout from "components/Layout";
import AdminRoute from "components/AdminRoute";
import MainImage from "components/MainImage";
import Links from "components/Links";
import {
  faUsers,
  faUserSecret,
  faChartLine,
  faGamepad,
  faPrint,
  faCalculator
} from "@fortawesome/free-solid-svg-icons";

export default function Admin() {
  const links = [
    {
      link: "/admin/games",
      label: "Create Game",
      image: faGamepad,
      color: "#e93b81",
    },
    {
      link: "/profile",
      label: "Profile",
      image: faUserSecret,
      color: "#aa2ee6",
    },
    {
      link: "/admin/events",
      label: "Create Event",
      image: faChartLine,
      color: "#29bb89",
    },
    {
      link: "/admin/userroles",
      label: "Users Role",
      image: faUsers,
      color: "#aa2ee6",
    },
    {
      link: "/admin/userReport",
      label: "Users Reports",
      image: faPrint,
      color: "#aa2ee6",
    },
    {
      link: "/admin/allEvents",
      label: "All Events",
      image: faCalculator,
      color: "#eed810",
    },
  ];
  return (
    <AdminRoute>
      <Layout>
        <MainImage />
        <Links links={links} />
      </Layout>
    </AdminRoute>
  );
}
