import axios from "axios";

export const saveEvents = async (data: any) => {
  return await axios({
    method: "post",
    url: "https://gamesfunctionapp.azurewebsites.net/api/V2/Event/StoreEvent",
    data,
  });
};


export const getAllEvents = async () => {
  return await axios({
    method: "get",
    url: "https://gamesfunctionapp.azurewebsites.net/api/V2/Event/GetEvents",
  });
};

export const activeEvent = async (grade: any) => {
  return await axios({
    method: "GET",
    url: `https://gamesfunctionapp.azurewebsites.net/api/V2/Event/GetActiveEventsForUser/${grade}`,
  });
};

export const searchEvent = async ({ data }: any) => {
  return await axios({
    method: "POST",
    url:
      "https://authfuncapp.azurewebsites.net/api/V2/Identity/GetAllUserEventReport",
    data,
  });
};
