/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, FC } from "react";
import AuthContext from "contexts/AuthContext";
import { useHistory } from "react-router-dom";

const AdminRoute: FC = ({ children }) => {
  const history = useHistory();
  const { verify, isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    const getUser = async () => {
      const user = await verify();
      if (!user || user.role === 1) {
        history.push("/");
      }
    };
    getUser();
  }, [isAuthenticated]);
  return <>{children}</>;
};

export default AdminRoute;
