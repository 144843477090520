import Layout from "components/Layout";
import { Card, createStyles, makeStyles, Theme, Box } from "@material-ui/core";
import AdminRoute from "components/AdminRoute";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import useEvents from "hooks/useEvents";
import Loading from "components/Shared/Loading";
import { useHistory } from "react-router-dom";

let theme = createTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#edf6f6",
      margin: "3% auto",
      borderRadius: "10px",
      border: "1px solid #ebeded",
      padding: "20px 40px",
      fontFamily: "'Bitter', serif",
      textAlign: "left",
      width: "75%",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      backgroundColor: "#ebf2f2",
      marginBottom: theme.spacing(4),
    },
  })
);

export default function AllEvents(): JSX.Element {
  const classes = useStyles();
  const { events, loading } = useEvents({});
  const history = useHistory()

  const columns = [
    { name: "eventId", label: "Id" },
    { name: "eventName", label: "Event Name" },
  ];

    
  const onRowClick = (data: any, n: any) => {
    const id = data[0];
    history.push(`/admin/allEvents/${id}`);
  };
  return (
    <ThemeProvider theme={theme}>
      <AdminRoute>
        <Layout>
          <Card className={classes.root} title="All Events">
            <Box mt={2}>
              <MUIDataTable
                title={"Events"}
                data={events}
                columns={columns}
                options={{
                  selectableRows: "none", // <===== will turn off checkboxes in rows
                  filter: false,
                  download: false,
                  print: false,
                  search: false,
                  viewColumns: false,
                  onRowClick,
                }}
              />
            </Box>
          </Card>
        </Layout>
      </AdminRoute>
      <Loading open={loading} />
    </ThemeProvider>
  );
}
