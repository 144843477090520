import React, { useContext, useEffect, useState } from "react";
import Text from "components/fillBlanks/Text";
import Answers from "components/fillBlanks/Answers";
import Layout from "components/Layout";
import {
  Card,
  createStyles,
  makeStyles,
  Grid,
  Snackbar,
} from "@material-ui/core";
import { updateGamePoints } from "services/games";
import UserRoute from "components/UserRoute";
import { useLocation } from "react-router-dom";
import AuthContext from "contexts/AuthContext";
import useGame from "hooks/useGame";
import Loading from "components/Shared/Loading";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#edf6f6",
      margin: "5% 11%",
      borderRadius: "10px",
      border: "1px solid #ebeded",
      padding: "20px",
      fontFamily: "'Bitter', serif",
      boxShadow: "unset",
    },
    header: {
      textAlign: "center",
      padding: "10px",
      color: "#3f72af",
      fontSize: "30px",
      fontWeight: 600,
    },
    answerHeader: {
      textAlign: "center",
      padding: "10px",
      color: "#3f72af",
      fontSize: "30px",
      fontWeight: 600,
    },

    container: {
      textAlign: "center",
    },
    submit: {
      color: "#fff",
      backgroundColor: "#3498DB",
      borderColor: "#3498DB",
      fontWeight: 400,
      boxSizing: "border-box",
      lineHeight: 1.5,
      textAlign: "center",
      display: "block",
      fontSize: "20px",
      borderRadius: "5px",
      transition: "all 0.5s ease",
      margin: "auto",
      marginTop: "20px",
      padding: "8px 25px",
      cursor: "pointer",
    },
  })
);

export default function FillBlanks(): JSX.Element {
  const classes = useStyles();
  const { verify: getCurrentUser } = useContext(AuthContext);
  const {
    state: { id, points, event },
  } = useLocation() as any;
  const { game, loading } = useGame({ id });
  const correctAnswers = game ? JSON.parse(game.gamesSolution) : [];
  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [finish, setFinish] = useState(false);

  const [answerArray, setAnswerArray] = useState<string[]>(
    new Array(correctAnswers.length).fill("")
  );
  const [dragged, setDragged] = useState<boolean[]>(
    new Array(correctAnswers.length).fill(false)
  );

  useEffect(() => {
    setAnswerArray(new Array(correctAnswers.length).fill(""));
  }, [correctAnswers.length]);

  const getScore = () => {
    let finalAnswersCount = 0;
    answerArray.forEach((a: string, i: number) => {
      if (a === correctAnswers[i]) {
        finalAnswersCount++;
      }
    });
    return Math.ceil(finalAnswersCount * (points / correctAnswers.length));
  };
  const handleSubmit = async () => {
    if (isSubmit) {
      return alert("you already submitted this game  ");
    }
    if (answerArray.some((a) => a === "")) {
      return alert("Please fill the blanks with the provided answers");
    }
    setIsSubmit(true);
    const score = getScore();
    const user = await getCurrentUser();
    const res = await updateGamePoints({
      gameId: id,
      points: score,
      eventGradeId: event.eventSimplified.eventGradeId,
      eventId: event.eventSimplified.eventId,
      profileId: user.profileId,
    });
    if (res) {
      setOpen(true);
      setFinish(true);
    }
  };

  return (
    <UserRoute>
      <Layout>
        <Card className={classes.root}>
          Please darg and drop the correct answer in the blank
          <h1 className={classes.header}>Fill in blanks</h1>
          <div className="page-content">
            <br />
            {!loading && (
              <Text
                text={JSON.parse(game ? game.gamesDetails : "")
                  .replace(
                    /\[[ 0-9]+]/g,
                    (str: string) => str.replaceAll(" ", "") + " "
                  )
                  .split(" ")}
                selected={selected}
                answerArray={answerArray}
                setAnswerArray={setAnswerArray}
                answers={correctAnswers}
                dragged={dragged}
                setDragged={setDragged}
              />
            )}
          </div>
        </Card>
        <Card className={classes.root}>
          <div className="page-content">
            <br />
            <h2 className={classes.answerHeader}>Choose The Right One</h2>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-around"
            >
              {!loading && (
                <Answers
                  answers={correctAnswers}
                  selected={selected}
                  setSelected={setSelected}
                  dragged={dragged}
                  setDragged={setDragged}
                  draggable={!finish}
                />
              )}
            </Grid>
            <br />
            <div className={classes.container}>
              <button className={classes.submit} onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </Card>
        <Loading open={loading} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          onClose={() => setOpen(false)}
          key={"top right"}
        >
          <Alert onClose={() => setOpen(false)} severity="success">
            Your score is {getScore()} / {points}
          </Alert>
        </Snackbar>
      </Layout>
    </UserRoute>
  );
}
