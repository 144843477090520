import React, { useContext, useState } from "react";
import Layout from "components/Layout";
import {
  Card,
  Grid,
  createStyles,
  makeStyles,
  Snackbar,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import UserRoute from "components/UserRoute";
import useGame from "hooks/useGame";
import Loading from "components/Shared/Loading";
import AuthContext from "contexts/AuthContext";
import { updateGamePoints } from "services/games";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: "5% auto",
      borderRadius: "10px",
      border: "1px solid #ebeded",
      padding: "20px",
      fontFamily: "'Bitter', serif",
      width: "52%",
      background: "#edf6f6",
    },
    header: {
      textAlign: "center",
      padding: "10px",
      color: "#3f72af",
      fontSize: "26px",
      fontWeight: 600,
    },
    container: {
      textAlign: "center",
    },
    submit: {
      color: "#fff",
      backgroundColor: "#3498DB",
      borderColor: "#3498DB",
      fontWeight: 400,
      boxSizing: "border-box",
      lineHeight: 1.5,
      textAlign: "center",
      display: "block",
      fontSize: "20px",
      borderRadius: "5px",
      transition: "all 0.5s ease",
      margin: "auto",
      marginTop: "20px",
      padding: "8px 25px",
      cursor: "pointer",
    },
    title: {
      color: "#112d4e",
      fontWeight: 600,
      textAlign: "center",
      fontSize: "21px",
    },
    word: {
      textAlign: "center",
      fontWeight: 600,
      margin: "auto",
      padding: "2% 0",
      borderRadius: 4,
      border: "1px solid #3498DB",
      color: "#3498DB",
      fontSize: "16px",
      background: "#dcebf3",
      width: "80%",
      height: 42,
    },
    input: {
      border: "1px solid #3498DB",
      color: "#999",
      fontWeight: 600,
      fontSize: "15px",
      padding: "0.375rem 0.75rem",
      lineHeight: 1.5,
      borderRadius: "0.25rem",
      margin: "auto",
      backgroundColor: "#FFF",
      width: "80%",
      height: 42,

      "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(55,90,127,0.25)",
      },
    },
    inputContainer: {
      textAlign: "center",
    },
    error: {
      textAlign: "center",
      color: "red",
      paddingTop: "5px",
      textTransform: "capitalize",
    },
  })
);

export default function WordScramble(): JSX.Element {
  const classes = useStyles();
  const { verify: getCurrentUser } = useContext(AuthContext);
  const {
    state: { id, points, event },
  } = useLocation() as any;
  const { game, loading } = useGame({ id });
  const words = game ? JSON.parse(game.gamesDetails) : [];
  const correctAnswers = game ? JSON.parse(game.gamesSolution) : [];
  const [answers, setAnswers] = useState<string[]>(
    Array(correctAnswers.length).fill("")
  );
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    let counter = 0;
    setIsSubmit(true);
    const user = await getCurrentUser();
    const isEmptyAnswers = answers.filter((a) => a.trim() === "").length;
    if (isEmptyAnswers) {
      return;
    }
    for (let i = 0; i < answers.length; i++) {
      if (answers[i].trim() === correctAnswers[i]) counter++;
    }
    const score = Math.ceil(counter * (points / words.length));
    const res = await updateGamePoints({
      gameId: id,
      points: score,
      eventGradeId: event.eventSimplified.eventGradeId,
      eventId: event.eventSimplified.eventId,
      profileId: user.profileId,
    });
    if (res) {
      setOpen(true);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: number
  ): void => {
    let tempAnswers = [...answers];
    tempAnswers[key] = e.currentTarget.value;
    setAnswers(tempAnswers);
  };

  return (
    <UserRoute>
      <Layout>
        <Card className={classes.root}>
          <h1 className={classes.header}>Word Scramble</h1>

          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-around"
          >
            <Grid item xs={6}>
              <h3 className={classes.title}>Scramble Words</h3>
            </Grid>
            <Grid item xs={6}>
              <h3 className={classes.title}>Answer</h3>
            </Grid>
            {words.map((word: string, key: number) => {
              return (
                <React.Fragment key={key}>
                  <Grid item xs={6}>
                    <div className={classes.word}>
                      <p>{word}</p>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.inputContainer}>
                      <input
                        type="text"
                        value={answers[key]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange(e, key)
                        }
                        placeholder="Enter Word Here"
                        className={classes.input}
                      />
                      {isSubmit && !answers[key] && (
                        <div className={classes.error}>
                          This field is required
                        </div>
                      )}
                    </div>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
          <div className={classes.container}>
            <button className={classes.submit} onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </Card>
        <Loading open={loading} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          onClose={() => setOpen(false)}
          key={"top right"}
        >
          <Alert onClose={() => setOpen(false)} severity="success">
            Your score has been saved
          </Alert>
        </Snackbar>
      </Layout>
    </UserRoute>
  );
}
