import React, { useRef, useState } from "react";
import Layout from "components/Layout";
import { createStyles, makeStyles, Card, Grid, Theme } from "@material-ui/core";
import { addGame } from "services/games";
import { Game } from "models/Game";
import { v4 as uuidv4 } from "uuid";
import { TextField } from "components/Shared";
import { useToasts } from "react-toast-notifications";
import AdminRoute from "components/AdminRoute";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#edf6f6",
      borderRadius: "10px",
      border: "1px solid #ebeded",
      padding: "5%",
      fontFamily: "'Bitter', serif",
      width: "50%",
      margin: "50px auto",
      minHeight: "72vh",
      [theme.breakpoints.down("md")]: {
        width: "90%",
      },
    },
    table: {
      textAlign: "center",
    },
    inputContainer: {
      padding: 0,
    },

    divider: {
      width: "100%",
      padding: "0 20px",
      border: 0,
      borderTop: "1px solid rgba(0,0,0,0.1)",
      height: 0,
      overflow: "visible",
    },
    input: {
      border: "1px solid #3498DB",
      width: "100%",
      color: "#6b7176",
      fontWeight: 500,
      fontSize: 16,
      height: 40,
      borderRadius: "0.25rem",
      padding: "0 12px",
      "&::placeholder": {
        color: "#999",
        opacity: 1,
      },
    },
    label: {
      padding: "0 0 0 5px",
      margin: 0,
      color: "#3f72af",
      fontSize: 18,
      fontWeight: 500,
      fontFamily: "'Bitter', serif",
      lineHeight: 1.5,
    },
    item: {
      width: "100%",
      borderRadius: 5,
      color: "#3498DB",
      fontSize: 18,
      padding: "10px 20px",
      marginBottom: "10px",
      background: "#dcebf3 !important",
      border: "1px solid #ebeded",
      height: "100%",
      fontWeight: "bold",
      "& input": {
        background: "#dcebf3 !important",
      },
    },
    heading: {
      margin: 0,
      fontSize: 24,
      color: "#112d4e",
      fontWeight: 600,
    },
    submit: {
      color: "#fff",
      backgroundColor: "#3498DB",
      borderColor: "#3498DB",
      fontWeight: 400,
      boxSizing: "border-box",
      lineHeight: 1.5,
      textAlign: "center",
      display: "block",
      fontSize: "20px",
      borderRadius: "5px",
      transition: "all 0.5s ease",
      margin: "auto",
      marginTop: "20px",
      padding: "8px 25px",
      cursor: "pointer",
    },
    error: {
      color: "#b00505",
      marginBottom: "25px",
      textAlign: "end",
    },
  })
);
export interface Word {
  word: string;
  answer: string;
}

export default function Matching(): JSX.Element {
  const numberRef = useRef<any>();
  const { addToast } = useToasts();
  const classes = useStyles();
  const [gameName, setGameName] = useState<string>("");
  const [numberOfWords, setNumberOfWords] = useState<number | string>("");
  const [words, setWords] = useState<Word[]>([]);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGameName(e.currentTarget.value);
  };
  const handleNumberChange = (e: any) => {
    if (e.key === "-") {
      numberRef.current.value = 1;
    }

    const number = +e.target.value;
    if (!number || Math.ceil(number) < 0 || Math.ceil(number) > 15) {
      return;
    }
    setNumberOfWords(isNaN(Number(e.target.value)) ? "" : Math.ceil(number));
    if (number)
      setWords(
        new Array<Word>(Math.ceil(number)).fill({ word: "", answer: "" })
      );
  };

  const checkIsValidWord = (word: Word) => {
    return !!word.answer && !!word.word;
  };
  const handleWordsChange = (
    word: string,
    type: "word" | "answer",
    index: number
  ) => {
    const old_words = [...words];

    if (type === "word") {
      old_words[index] = {
        word,
        answer: old_words[index].answer,
      };
      setWords(old_words);
    } else {
      old_words[index] = {
        word: old_words[index].word,
        answer: word,
      };
      setWords(old_words);
    }
  };
  const handleSubmit = async () => {
    setIsSubmit(true);
    const hasEmptyWord = words.some((w) => !checkIsValidWord(w));
    if (hasEmptyWord || !words.length) return;
    const newGame: Game = {
      gameId: uuidv4(),
      gameName: gameName,
      gameType: "matching",
      gamesDetails: JSON.stringify(words),
      gamesSolution: JSON.stringify(words),
    };
    const { data } = await addGame(newGame);
    if (data.isSuccess) {
      addToast("Game Created Successfully", {
        appearance: "success",
        autoDismiss: true,
      });
    } else {
      addToast(data.errorMessage, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  return (
    <AdminRoute>
      <Layout>
        <Card className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div>
                <label
                  style={{ color: isSubmit && !gameName ? "red" : "#3f72af" }}
                  htmlFor="gameName"
                  className={classes.label}
                >
                  Game Name *
                </label>
                <input
                  id="gameName"
                  value={gameName}
                  placeholder="Game Name"
                  onChange={handleNameChange}
                  className={classes.input}
                  required
                  style={{
                    marginBottom: isSubmit && !gameName ? 0 : 25,
                    borderColor: isSubmit && !gameName ? "red" : "",
                  }}
                />
                {isSubmit && !gameName ? (
                  <div className={classes.error}>Required</div>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <label
                  htmlFor="numberOfWords"
                  className={classes.label}
                  style={{ color: isSubmit && !gameName ? "red" : "#3f72af" }}
                >
                  Number Of Matching Words *
                </label>

                <input
                  id="numberOfWords"
                  onChange={handleNumberChange}
                  onKeyDown={handleNumberChange}
                  ref={numberRef}
                  value={numberOfWords}
                  type="number"
                  max={15}
                  min={1}
                  step={1}
                  placeholder="6"
                  className={classes.input}
                  required
                  style={{
                    marginBottom: isSubmit && !numberOfWords ? 0 : 25,
                    borderColor: isSubmit && !numberOfWords ? "red" : "",
                  }}
                />
                {isSubmit && !numberOfWords ? (
                  <div className={classes.error}>Required</div>
                ) : null}
              </div>
            </Grid>

            <hr className={classes.divider} />
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                spacing={4}
                className={classes.table}
              >
                <Grid item xs={6} className={classes.inputContainer}>
                  <h3 className={classes.heading}>Words</h3>
                </Grid>
                <Grid item xs={6} className={classes.inputContainer}>
                  <h3 className={classes.heading}>Answers</h3>
                </Grid>
                {words.map(({ word, answer }, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={6}>
                      <TextField
                        error={isSubmit && !word}
                        inputClassName={classes.item}
                        type="text"
                        onChange={(e) =>
                          handleWordsChange(e.target.value, "word", index)
                        }
                      />
                      {isSubmit && !word ? (
                        <div className={classes.error}>
                          This field cannot be empty
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="text"
                        error={isSubmit && !answer}
                        inputClassName={classes.item}
                        onChange={(e) =>
                          handleWordsChange(e.target.value, "answer", index)
                        }
                      />
                      {isSubmit && !answer ? (
                        <div className={classes.error}>
                          This field cannot be empty
                        </div>
                      ) : null}
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <hr />
          <button className={classes.submit} onClick={handleSubmit}>
            Submit
          </button>
        </Card>
      </Layout>
    </AdminRoute>
  );
}
