import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Loading = ({ open = false }: { open: boolean }) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open} onClick={() => {}}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loading;