import React, { useState } from "react";
import Layout from "components/Layout";
import axios from "axios";
import "bootstrap";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { css } from "@emotion/react";
import ClockLoader from "react-spinners/ClockLoader";
import { Card, createStyles, makeStyles } from "@material-ui/core";
import AdminRoute from "components/AdminRoute";
import { Select } from "components/Shared";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#edf6f6",
      margin: "3% auto",
      borderRadius: "10px",
      border: "1px solid #ebeded",
      padding: "20px 40px",
      fontFamily: "'Bitter', serif",
      textAlign: "left",
      width: "75%",
    },
    inputs: {
      width: "100%",
      marginBottom: "10px",
      color: "#999",
      fontWeight: 500,
      fontSize: 18,
      height: 40,
      border: "1px solid #3498DB",
      display: "block",
      padding: "0.375rem 0.75rem",
      background: "#fff",
      borderRadius: ".25rem",
    },
  })
);
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function UserRoles() {
  const classes = useStyles();
  const [userName, setUserName] = useState<string>("");
  const [churchName, setChurchName] = useState<string>("");
  const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };

  const handleChurchNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChurchName(e.target.value);
  };

  const [data, setData] = useState({ profiles: [] });
  const [finalData, setFinalData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(true);

  const handleRoleChange = (
    item: profile,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    var selectedRole = e.target.value;
    const profiles = finalData.map((p: any) => {
      if (p.profileId === item.profileId) {
        return { ...p, role: parseInt(selectedRole) };
      }
      return p;
    });
    setFinalData(profiles);
  };

  interface profile {
    profileId: string;
    firstName: string;
    lastName: string;
    role: string;
  }

  return (
    <AdminRoute>
      <Layout>
        <Card className={classes.root}>
          <input
            className={classes.inputs}
            id="userName"
            value={userName}
            onChange={handleUserNameChange}
            placeholder="User Name"
          ></input>
          <input
            className={classes.inputs}
            id="churchName"
            value={churchName}
            onChange={handleChurchNameChange}
            placeholder="Church Name"
          ></input>
          <div className="col text-center">
            <button onClick={getUsers} className="btn btn-primary">
              Search
            </button>
          </div>
        </Card>
        <ClockLoader
          css={override}
          size={50}
          color={"#7DDEAF"}
          loading={!loading}
        />
        {finalData != null && finalData.length > 0 && (
          <Card className={classes.root}>
            <table className="table">
              <tr>
                <th>Name</th>
                <th>Role</th>
              </tr>
              {finalData
                .filter(
                  (a: profile) =>
                    a.firstName.length < 30 && a.lastName.length < 30
                )
                .map((item: profile) => (
                  <tr key={item.profileId}>
                    <td>
                      {item.firstName} {item.lastName}
                    </td>
                    <td>
                      <Select
                        options={[
                          { value: 3, label: "Admin" },
                          { value: 2, label: "Servant" },
                          { value: 1, label: "User" },
                        ]}
                        label=""
                        value={item.role}
                        onChange={(e: any) => handleRoleChange(item, e)}
                        name="role"
                      />
                    </td>
                  </tr>
                ))}
            </table>
            <div className="col text-center">
              <button onClick={updateUsers} className="btn btn-primary">
                Update Role(s)
              </button>
            </div>
          </Card>
        )}
        <ClockLoader
          css={override}
          size={50}
          color={"#7DDEAF"}
          loading={!loadingUpdate}
        />
      </Layout>
    </AdminRoute>
  );

  function getUsers() {
    setData({ profiles: [] });
    if (
      (userName == null || userName.length < 1) &&
      (churchName == null || churchName.length < 1)
    ) {
      alert("at least one input should be provided");
      return;
    }
    const userSearchDto = {
      userName: userName,
      churchName: churchName,
    };

    setLoading(!loading);

    axios
      .post(
        `https://authfuncapp.azurewebsites.net/api/V2/Identity/SearchUsers`,
        userSearchDto
      )
      .then((res) => {
        var data = res.data;
        setData(data);
        setFinalData(data.profiles);
        setLoading(loading);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function updateUsers() {
    setData(data);
    setLoadingUpdate(!loadingUpdate);
    axios
      .post(
        `https://authfuncapp.azurewebsites.net/api/V2/Identity/UpdateUsers`,
        finalData
      )
      .then((res) => {
        setLoadingUpdate(loadingUpdate);
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
