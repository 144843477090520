import React from "react";
import {
  Select,
  FormControl,
  withStyles,
  alpha,
  makeStyles,
} from "@material-ui/core";
import { Option } from "models/Input";

const NativeSelect = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
    },
  },
  select: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    // border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(Select);
const useStyles = makeStyles((theme) => ({
  margin: {
    width: "100%",
    margin: 0,
  },
  label: {
    textTransform: "capitalize",
    color: "#443e3e",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "left",
  },
}));

const TextField = ({
  label,
  onChange,
  type,
  onBlur,
  value,
  name,
  options,
  multi = false,
  inputClassName,
}: {
  label: string;
  onChange: any;
  type?: string;
  onBlur?: any;
  value: string | string[];
  name: string;
  options: Option[];
  multi?: boolean;
  inputClassName?: string;
}) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.margin}>
      <label className={classes.label}>{label.replaceAll("_", " ")}</label>
      <NativeSelect
        style={{ margin: 0 }}
        value={value}
        onChange={onChange}
        name={name}
        multiple={multi}
        native
        inputProps={{ "aria-label": "age" }}
        className={inputClassName}
      >
        {options.map(({ value, label }: Option) => (
          <option value={value}>{label}</option>
        ))}
      </NativeSelect>
    </FormControl>
  );
};

export default TextField;
