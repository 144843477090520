import { createContext, useReducer } from "react";
import type { FC, ReactNode } from "react";
import { getProfileByEmail } from "services/auth";
import * as msal from "msal";
import { msalAppConfig } from "../msalAppConfig";


interface AuthState {
  isAuthenticated: boolean;
}

interface AuthContextValue extends AuthState {
  auth: any;
  logout: any;
  verify: any;
}

interface AuthProviderProps {
  children: ReactNode;
}

type LoginAction = {
  type: "LOGIN";
  payload: {
    isAuthenticated: boolean;
  };
};
type LogoutAction = {
  type: "LOGOUT";
};
type AuthAction = {
  type: "AUTH";
};

type Action = LoginAction | LogoutAction | AuthAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case "LOGIN": {
      const { isAuthenticated } = action.payload;
      return {
        isAuthenticated,
      };
    }

    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
      };
    }
    case "AUTH": {
      return {
        isAuthenticated: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  logout: () => Promise.resolve(),
  verify: () => {},
  auth: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);


  const verifyUser = async () => {
    const msalApp = new msal.UserAgentApplication(msalAppConfig);
    const account = msalApp.getAccount();
    const { data } = await getProfileByEmail(account.idToken.emails[0]);
    dispatch({
      type: "AUTH",
    });
    return data;
  };

  const logoutUser = async () => {
    const msalApp = new msal.UserAgentApplication(msalAppConfig);
    msalApp.logout()
    dispatch({
      type: "LOGOUT",
    });
  };

  const auth = () => {
    dispatch({
      type: "AUTH",
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logout: logoutUser,
        verify: verifyUser,
        auth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
