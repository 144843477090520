import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { Box, Snackbar } from "@material-ui/core";
import Alert, { Color } from "@material-ui/lab/Alert";

import Layout from "components/Layout";
import Loader from "components/Loader";

import { TextField, Card } from "components/Shared";
import AdminRoute from "components/AdminRoute";

const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const StyledFile = styled.input`
  padding: 4px 0 4px 10px;
  margin-bottom: 15px;
  border: 1px solid #98c4e1;
  height: 38px;
  font-size: 16px;
  -moz-box-shadow: 2px 2px 4px 0px #98c4e1;
  -webkit-box-shadow: 2px 2px 4px 0px #98c4e1;
  box-shadow: 2px 2px 4px 0px #98c4e1;
  -moz-border-radius: 7px 7px 7px 7px;
  -webkit-border-radius: 7px 7px 7px 7px;
  border-radius: 7px 7px 7px 7px;
  background-color: #fff;
  width: 100%;
`;

const Label = styled.div`
  color: #443e3e;
  font-size: 16px;
  text-align: left;
  font-weight: 500;
  text-transform: capitalize;
`;

interface ErrorStatus {
  status?: Color;
  message?: string;
}

export default function MazeServant(): JSX.Element {
  const [errorStatus, setErrorStatus] = useState<ErrorStatus>({});
  const [open, setOpen] = useState(false);
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);

  return (
    <AdminRoute>
      <Layout>
        <Loader isOpen={isLoaderOpen} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          onClose={() => setOpen(false)}
          key={"top right"}
        >
          <Alert onClose={() => setOpen(false)} severity={errorStatus.status}>
            {errorStatus.message}
          </Alert>
        </Snackbar>
        <Formik
          initialValues={{
            gameName: "",
            noOfRows: 10,
            noOfColumns: 10,
            background: "",
            character: "",
          }}
          onSubmit={(values) => {
            setIsLoaderOpen(true);
            const storeGame = {
              isSuccess: true,
              errorMessage: "string",
              gameId: uuidv4(),
              gameName: values.gameName,
              gameType: "maze",
              gamesDetails: JSON.stringify(values),
              gamesSolution: "string",
            };
            return axios
              .post(
                "https://gamesfunctionapp.azurewebsites.net/api/V2/Games/StoreGame",
                storeGame
              )
              .then((response) => {
                setErrorStatus({
                  status: response.data.isSuccess ? "success" : "error",
                  message: response.data.isSuccess
                    ? "Game has been submitted successfully!"
                    : "Error submitting the game",
                });
                setOpen(true);
                setIsLoaderOpen(false);
              })
              .catch((error) => console.log(error));
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Card onClick={handleSubmit} title="Maze">
                <Box mb={2}>
                  <TextField
                    key={1}
                    label="Game Name"
                    value={values.gameName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="gameName"
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                </Box>
                <hr />
                <Box mb={2}>
                  <TextField
                    key={1}
                    label="No. of rows"
                    value={values.noOfRows}
                    onChange={(e: any) => {
                      if (e.key === "-") {
                        e.target.value = "";
                      }
                      const number = +e.target.value;
                      if (
                        !number ||
                        Math.ceil(number) < 0 ||
                        isNaN(number) ||
                        number > 60
                      ) {
                        return;
                      }
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    type="number"
                    name="noOfRows"
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    key={1}
                    label="No. of columns"
                    value={values.noOfColumns}
                    onChange={(e: any) => {
                      if (e.key === "-") {
                        e.target.value = "";
                      }
                      const number = +e.target.value;
                      if (
                        !number ||
                        Math.ceil(number) < 0 ||
                        isNaN(number) ||
                        number > 60
                      ) {
                        return;
                      }
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    name="noOfColumns"
                  />
                </Box>
                <Box mb={2}>
                  <Label>Upload Image</Label>
                  <StyledFile
                    type="file"
                    onChange={(e: any) => {
                      setIsLoaderOpen(true);
                      const formData = new FormData();
                      formData.append("File", e.target.files[0]);
                      formData.append("Description", e.target.files[0].name);
                      return axios
                        .post(
                          "https://gamesfunctionapp.azurewebsites.net/api/V2/Media/UploadMedia",
                          formData
                        )
                        .then((response) => {
                          setOpen(true);
                          if (response.data.isSuccess) {
                            setFieldValue(`background`, e.target.files[0].name);
                          }
                          setErrorStatus({
                            status: response.data.isSuccess
                              ? "success"
                              : "error",
                            message: response.data.isSuccess
                              ? "File uploaded successfully"
                              : "Error uploading the file",
                          });
                          setIsLoaderOpen(false);
                        })
                        .catch((error) => console.log(error));
                    }}
                  />
                </Box>
                <Box mb={2}>
                  <Label>Upload character</Label>
                  <StyledFile
                    type="file"
                    onChange={(e: any) => {
                      setIsLoaderOpen(true);
                      const formData = new FormData();
                      formData.append("File", e.target.files[0]);
                      formData.append("Description", e.target.files[0].name);
                      return axios
                        .post(
                          "https://gamesfunctionapp.azurewebsites.net/api/V2/Media/UploadMedia",
                          formData
                        )
                        .then((response) => {
                          setOpen(true);
                          if (response.data.isSuccess) {
                            setFieldValue(`character`, e.target.files[0].name);
                          }
                          setErrorStatus({
                            status: response.data.isSuccess
                              ? "success"
                              : "error",
                            message: response.data.isSuccess
                              ? "File uploaded successfully"
                              : "Error uploading the file",
                          });
                          setIsLoaderOpen(false);
                        })
                        .catch((error) => console.log(error));
                    }}
                  />
                </Box>
              </Card>
            </Form>
          )}
        </Formik>
      </Layout>
    </AdminRoute>
  );
}
