import { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// User Route
import Home from "pages/User/Home";
import Profile from "pages/User/Profile";
import Games from "pages/User/Games";
import Coptic from "pages/User/Coptic";
import WordSearch from "pages/User/WordSearch";
import ColoringGame from "pages/User/ColoringGame";
import FillBlanks from "pages/User/FillBlanks";
import Matching from "./pages/User/Matching";
import WordScramble from "pages/User/WordScramble";
import Maze from "pages/User/Maze";
// Admin Route
import MatchingServant from "./pages/Admin/Matching";
import FillBlancksServant from "pages/Admin/FillBlanks";
import WordScrambleServent from "pages/Admin/WordScramble";
import CopticServant from "pages/Admin/CopticServant";
import MazeServant from "pages/Admin/MazeServant";
import UserRoles from "./pages/Admin/UserRoles";
import Events from "pages/Admin/Events";
import Admin from "pages/Admin/Admin";
import GameDetails from "pages/Admin/GameDetails";
import GameDetailsType from "pages/Admin/GameDetailsType";
import AdminGames from "pages/Admin/AdminGamesHome";
import AdminUserReport from "pages/Admin/UserReport";
import AllEvents from "pages/Admin/AllEvents";
import OneEvent from "pages/Admin/OneEvent";
// Public Route
import Register from "pages/Auth/Register";
import Login from "pages/Auth/Login";

class Routers extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          {/* Public Route */}
          <Route path="/" component={Login} exact />
          <Route path="/register" component={Register} exact />
          {/* admin Route */}
          <Route path="/admin" component={Admin} exact />
          <Route path="/admin/events" component={Events} exact />
          <Route path="/admin/userroles" component={UserRoles} exact />
          <Route path="/admin/matching" component={MatchingServant} exact />
          <Route path="/admin/game-details" component={GameDetails} exact />
          <Route
            path="/admin/game-details-type"
            component={GameDetailsType}
            exact
          />
          <Route path="/admin/fill-blanks" component={FillBlancksServant} />
          <Route path="/admin/word-scramble" component={WordScrambleServent} />
          <Route path="/admin/coptic" component={CopticServant} exact />
          <Route path="/admin/maze" component={MazeServant} exact />
          <Route path="/admin/games" component={AdminGames} exact />
          <Route path="/admin/userReport" component={AdminUserReport} exact />
          <Route path="/admin/allEvents" component={AllEvents} exact />
          <Route path="/admin/allEvents/:id" component={OneEvent} exact />

          {/* user Route */}
          <Route path="/home" component={Home} />
          <Route exact path="/games" component={Games} />
          <Route path="/games/word-search/" component={WordSearch} />
          <Route path="/games/coloring-game/" component={ColoringGame} />
          <Route path="/games/fill-blanks/" component={FillBlanks} />
          <Route path="/games/coptic" component={Coptic} />
          <Route path="/games/word-scramble/" component={WordScramble} />
          <Route path="/games/matching/" component={Matching} />
          <Route path="/games/maze/" component={Maze} />
          <Route path="/profile" component={Profile} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Routers;
