import { useEffect, useState } from "react";
import { getCountryList } from "services/countries";
import { Country } from "models/Country";

const useDemographic = () => {
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getCountryList();
        setCountries(data.countriesCollection);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    countries,
  };
};

export default useDemographic;
