import styled from "styled-components";
import { FadeLoader } from "react-spinners";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: #6097b547;
  z-index: 1200;
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #6097b5;
`;

interface Props {
    isOpen: boolean;
}

export default function Loader({ isOpen = false }: Props) {
  return (
    (isOpen && (
      <Wrapper>
        <Content>
          <FadeLoader color="#6097B5" />
          <br />
          <br />
          <br />
          <br /> Loading ...
        </Content>
      </Wrapper>
    )) ||
    null
  );
}
