import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components/Shared";
import { Link as LinkButton } from "react-router-dom";

export default function Link({
  link,
  label,
  image,
  color,
}: {
  link: string;
  label: string;
  image: any;
  color: string;
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    div: {
      width: "200px",
      height: "200px",
      padding: 0,
      backgroundSize: "cover",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      margin: "auto",
    },
    link: {
      width: "50%",
      height: 45,
      backgroundColor: "#FFFFFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 25,
      cursor: "pointer",
    },
    icon: {
      borderRadius: "50%",
      padding: 30,
      width: "70% !important",
      height: "100%",
      color: "#FFFFFF",
      background: color,
      transition: ".8s all",
      marginBottom: 20,
      border: `1px solid ${color}`,
      "&:hover": {
        color,
        background: "#FFFFFF",
        borderColor: color,
        borderWidth: 1,
      },
    },
    button: {
      width: "100%",
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.div}>
        <FontAwesomeIcon
          className={clsx(classes.icon, "link-icon")}
          icon={image}
        />
        <LinkButton to={link}>
          <Button text={label} style={{ width: "100%" }} />
        </LinkButton>
      </div>
    </div>
  );
}
