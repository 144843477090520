import { useState } from "react";
import Layout from "components/Layout";
import {
  Card,
  createStyles,
  makeStyles,
  Theme,
  Grid,
  Box,
} from "@material-ui/core";
import AdminRoute from "components/AdminRoute";
import { TextField, Button, Select } from "components/Shared";
import { searchEvent } from "services/events";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import useChurches from "hooks/useChurches";
import useEvents from "hooks/useEvents";
import Loader from "components/Loader";

let theme = createTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#edf6f6",
      margin: "3% auto",
      borderRadius: "10px",
      border: "1px solid #ebeded",
      padding: "20px 40px",
      fontFamily: "'Bitter', serif",
      textAlign: "left",
      width: "75%",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
      backgroundColor: "#ebf2f2",
      marginBottom: theme.spacing(4),
    },
  })
);

export default function UserReport(): JSX.Element {
  const { loading, churches } = useChurches();
  const { loading: eventLoading, events } = useEvents({});
  const classes = useStyles();
  const [searchField, setSearchField] = useState({
    userName: "",
    churchName: churches ? churches[0] : "",
    eventName: events.length ? (events[0] as any).eventName : "",
  });
  const [result, setResult] = useState([]);

  const handleChange = (key: string, value: any) => {
    setSearchField({ ...searchField, [key]: value });
  };

  const handleSearch = async (e: any) => {
    const { data } = await searchEvent({
      data: searchField,
    });
    const finalResult = data.map((user: any, index: number) => {
      return user.userGamesDetails.map((game: any) => ({
        ...user,
        gameName: game.gameName,
        points: game.userGainedPoints,
        id: index + 1,
      }));
    });

    setResult(finalResult.flat());
  };
  const columns = [
    { name: "churchName", label: "Church Name" },
    { name: "userFullName", label: "User Name" },
    { name: "eventName", label: "Event Name" },
    { name: "gameName", label: "Game Name" },
    { name: "points", label: "Points" },
  ];

  if (loading || eventLoading) return <Loader isOpen={loading} />;
  return (
    <ThemeProvider theme={theme}>
      <AdminRoute>
        <Layout>
          <Card className={classes.root} title="User Report">
            <Grid container spacing={3}>
              <Grid item xs={6} md={3} lg={3}>
                <Select
                  label={"Church Name"}
                  onChange={(e: any) =>
                    handleChange("churchName", e.target.value)
                  }
                  name="churchName"
                  value={searchField.churchName}
                  options={
                    churches
                      ? [
                          { label: "select the church", value: "" },
                          ...churches.map((_) => ({ label: _, value: _ })),
                        ]
                      : []
                  }
                />
              </Grid>

              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  label={"User Name"}
                  onChange={(e) => handleChange("userName", e.target.value)}
                  input_type="text"
                />
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <Select
                  label={"Event Name"}
                  onChange={(e: any) =>
                    handleChange("eventName", e.target.value)
                  }
                  name="eventName"
                  value={searchField.eventName}
                  options={
                    events
                      ? [
                          { label: "select the event", value: "" },
                          ...events.map((_: any) => ({
                            label: _.eventName,
                            value: _.eventName,
                          })),
                        ]
                      : []
                  }
                />
              </Grid>
              <Grid item xs={6} md={3} lg={3}>
                <TextField
                  label={"Event Date"}
                  onChange={(e) => handleChange("eventDate", e.target.value)}
                  input_type="date"
                />
              </Grid>
              <Grid item xs={9} md={9} lg={9}></Grid>
              <Grid item xs={3} md={3} lg={3}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button text="Search" type="submit" onClick={handleSearch} />
                </div>
              </Grid>
            </Grid>
            <Box mt={2}>
              <MUIDataTable
                title={"Users"}
                data={result}
                columns={columns}
                options={{
                  selectableRows: "none", // <===== will turn off checkboxes in rows
                  filter: false,
                  download: false,
                  print: false,
                  search: false,
                  viewColumns: false,
                }}
              />
            </Box>
          </Card>
        </Layout>
      </AdminRoute>
    </ThemeProvider>
  );
}
