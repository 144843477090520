import axios from "axios";
import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Layout from "components/Layout";
import UserRoute from "components/UserRoute";
import { updateGamePoints } from "services/games";
import { useLocation } from "react-router-dom";
import AuthContext from "contexts/AuthContext";
import Alert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import useCopticMedia from "hooks/useCopticMedia";
import Loader from "components/Loader";

interface GameDetails {
  gameName: string;
  numberOfAudioWords: number;
  questions: [
    {
      answer: string;
      audio: string;
      option0: string;
      option1: string;
      option2: string;
      option3: string;
      [key: string]: number | string;
    }
  ];
}

const Wrapper = styled.div`
  width: 60%;
  margin: 50px auto;
  background-color: #edf6f6;
  border-radius: 10px;
  border: 1px solid #ebeded;
  padding: 20px;
  text-align: center;
`;

const GridItem = styled(Grid)`
  text-align: center;
  font-size: 24px;
  color: #112d4e;
  font-weight: 600;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  justify-content: left;
  margin-top: 5px;

  .MuiSvgIcon-root {
    width: 0.6em;
    height: 0.6em;
  }

  .MuiTypography-body1 {
    font-size: 15px;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: #3498db;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  background-color: #fff;
  border: 1px solid #98c4e1;
  height: 42px;
  padding: 0 0 0 10px;
  -moz-box-shadow: 2px 2px 4px 0px #98c4e1;
  -webkit-box-shadow: 2px 2px 4px 0px #98c4e1;
  box-shadow: 2px 2px 4px 0px #98c4e1;
  -moz-border-radius: 7px 7px 7px 7px;
  -webkit-border-radius: 7px 7px 7px 7px;
  border-radius: 7px 7px 7px 7px;
  margin: 0;
  vertical-align: middle;
  font-size: 16px;
  color: #3498db;
  font-weight: 600;
`;

const StyledButton = styled(Button)`
  margin: 30px;
  color: #fff;
  background-color: #3498db;
  border-color: #3498db;
  padding: 8px 30px;
`;

const StyledAudio = styled.audio`
  -moz-box-shadow: 2px 2px 4px 0px #98c4e1;
  -webkit-box-shadow: 2px 2px 4px 0px #98c4e1;
  box-shadow: 2px 2px 4px 0px #98c4e1;
  -moz-border-radius: 7px 7px 7px 7px;
  -webkit-border-radius: 7px 7px 7px 7px;
  border-radius: 7px 7px 7px 7px;
  height: 42px;
  margin: 5px 0;
`;

const Title = styled.div`
  text-align: center;
  padding: 3px 10px;
  color: #3f72af;
  font-size: 30px;
  font-weight: 600;
  margin: 15px 0 10px 0;
`;

export default function Coptic(): JSX.Element | null {
  const [userAnswers, setUserAnswers] = useState<any>({});
  const [, setIsLoaderOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [gameDetails, setGameType] = useState<GameDetails | null>(null);
  const { loading, questions } = useCopticMedia({
    gameDetails,
  });

  const {
    state: { id, points, event },
  } = useLocation() as any;
  const { verify: getCurrentUser } = useContext(AuthContext);

  useEffect(() => {
    setIsLoaderOpen(true);

    axios
      .get("https://gamesfunctionapp.azurewebsites.net/api/V2/Games")
      .then((response) => {
        const gameDetails = response.data
          .filter((i: any) => i.gameType === "Coptic")
          .at(-1);
        setGameType(JSON.parse(gameDetails.gamesDetails));
        setIsLoaderOpen(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleChange = (answer: string | number, index: number) => {
    setUserAnswers({
      ...userAnswers,
      [index]: {
        value: answer,
        isCorrect: gameDetails?.questions[index].answer === answer,
      },
    });
  };

  if (!gameDetails?.questions?.length) {
    return null;
  }

  const handleSubmit = async () => {
    setIsLoaderOpen(true);
    let score;
    const lengthOfCorrectAnswers = Object.keys(userAnswers).filter(
      (answer: any) => userAnswers[answer].isCorrect
    ).length;

    if (!lengthOfCorrectAnswers) {
      score = 0;
    } else {
      score = Math.ceil(
        Object.keys(userAnswers).length * (points / lengthOfCorrectAnswers)
      );
    }

    const user = await getCurrentUser();

    await updateGamePoints({
      gameId: id,
      points: score,
      eventGradeId: event.eventSimplified.eventGradeId,
      eventId: event.eventSimplified.eventId,
      profileId: user.profileId,
    });
    setOpen(true);
    setIsLoaderOpen(false);
  };
  if (loading) return <Loader isOpen={loading} />;
  return (
    <UserRoute>
      <Layout>
        <Wrapper>
          <Title>Coptic</Title>
          <Grid container spacing={3}>
            <GridItem item xs={6}>
              Coptic Audio
            </GridItem>
            <GridItem item xs={6}>
              Answer
            </GridItem>
          </Grid>
          {questions.map((question: any, questionIndex: number) => (
            <Grid key={questionIndex} container spacing={3}>
              <GridItem item sm={12} md={6}>
                <StyledAudio controls>
                  <source src={question.audio} type="audio/ogg" />
                </StyledAudio>
              </GridItem>
              <Grid item sm={12} md={6} style={{ textAlign: "center" }}>
                <StyledRadioGroup key={`answer-${questionIndex}`} row>
                  <Grid container>
                    {[1, 2, 3, 4].map((answer, index) => {
                      return (
                        <Grid item xs={3} key={`${answer}-${index}`}>
                          <StyledFormControlLabel
                            checked={
                              userAnswers[questionIndex]?.value ===
                              question[`option${index}`]
                            }
                            control={<Radio />}
                            label={question[`option${index}`]}
                            onChange={(event) =>
                              handleChange(
                                question[`option${index}`],
                                questionIndex
                              )
                            }
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </StyledRadioGroup>
              </Grid>
            </Grid>
          ))}
          <StyledButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </StyledButton>
        </Wrapper>
      </Layout>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={() => setOpen(false)}
        key={"top right"}
      >
        <Alert onClose={() => setOpen(false)} severity="success">
          Your score has been saved
        </Alert>
      </Snackbar>
    </UserRoute>
  );
}
