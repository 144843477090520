import { Box, Grid, Chip } from "@material-ui/core";
import Layout from "components/Layout";
import type { Event } from "models/Event";
import { TextField, Card } from "components/Shared";
import { FieldType } from "models/Input";
import { gradesOptions } from "constants/grades";

import AdminRoute from "components/AdminRoute";
import Loading from "components/Shared/Loading";
import moment from "moment";
import { useParams } from "react-router-dom";
import useEvents from "hooks/useEvents";
import useGames from "hooks/useGames";

const fields: FieldType[] = [
  {
    key: "eventName",
    label: "Event Name",
    type: "text",
    maxLength: 24,
  },
  {
    key: "startDate",
    label: "start date",
    type: "date",
    maxLength: 24,
    min: moment().format("YYYY-MM-DD"),
  },
  {
    key: "endDate",
    label: "end date",
    type: "date",
    min: moment().format("YYYY-MM-DD"),
    options: [],
  },
  {
    key: "grade",
    label: "grade",
    type: "multi_select",
    options: gradesOptions,
  },
  {
    key: "points",
    label: "points",
    type: "number",
    maxLength: 6,
  },
];

export default function OneEventPage() {
  const { id } = useParams<{ id: string }>();
  const { oneEvent, loading: eventLoading } = useEvents({ id });
  const { games, loading: gamesLoading } = useGames();

  const eventGames =
    eventLoading || gamesLoading
      ? []
      : oneEvent.eventGameDetails.map((game: any) => {
          const oneGame = games?.find(
            ({ gameId }: any) => gameId === game.gameId
          );
          return { gameName: oneGame?.gameName, points: game.points };
        });

  if (eventLoading || gamesLoading)
    return <Loading open={eventLoading || gamesLoading} />;

  return (
    <AdminRoute>
      <Layout>
        <Card title={`Event : ${oneEvent.eventName}`}>
          <Box mb={3}>
            <Grid container>
              {fields.map(
                (
                  {
                    key: _,
                    type,
                    options,
                    label,
                    min,
                    max,
                    maxLength,
                  }: FieldType,
                  index: number
                ) => {
                  const key = _ as keyof Event;
                  if (key === "points") {
                    return (
                      <Grid key={index} item xs={12} md={12} lg={12}>
                        <Box mt={1} mb={1}>
                          {eventGames.map((game: any) => (
                            <Chip
                              label={`${game.gameName} ${
                                game.points ? `(${game.points})` : ""
                              }`}
                              key={game.gameName}
                            />
                          ))}
                        </Box>
                      </Grid>
                    );
                  }

                  if (key === "grade") {
                    return (
                      <Grid key={index} item xs={12} md={12} lg={12}>
                        <Box mt={1} mb={1}>
                          {oneEvent.grade.map((grade: any) => (
                            <Chip label={grade} key={grade} />
                          ))}
                        </Box>
                      </Grid>
                    );
                  }
                  if (type === "date") {
                    return (
                      <Grid item xs={12} md={12} lg={12}>
                        <Box mt={1} mb={1}>
                          <TextField
                            key={index}
                            label={label}
                            value={moment(oneEvent[key]).format("YYYY-MM-DD")}
                            type={type}
                            name={key}
                            disabled
                          />
                        </Box>
                      </Grid>
                    );
                  }
                  return (
                    <Grid item xs={12} md={12} lg={12}>
                      <Box mt={1} mb={1}>
                        <TextField
                          key={index}
                          label={label}
                          value={oneEvent[key]}
                          type={type}
                          name={key}
                          disabled
                        />
                      </Box>
                    </Grid>
                  );
                }
              )}
            </Grid>
          </Box>
        </Card>
      </Layout>
    </AdminRoute>
  );
}
