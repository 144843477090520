import React from "react";
import { Box, Grid } from "@material-ui/core";
import Layout from "components/Layout";
import { Formik, Form } from "formik";
import { TextField, Card, Select } from "components/Shared";
import { FieldType } from "models/Input";
import { GameDetails } from "models/Game";
import AdminRoute from "components/AdminRoute";

const game_details: FieldType[] = [
  {
    key: "game_name",
    label: "Game Name",
    type: "select",
    options: [
      {
        label: "Game Name 1",
        value: "game1",
      },
      {
        label: "Game Name 2",
        value: "game1",
      },
      {
        label: "Game Name 3",
        value: "game1",
      },
    ],
  },
  {
    key: "game_details",
    label: "Game Details",
    type: "text",
  },

  {
    key: "game_details_type",
    label: "game details type",
    type: "select",
    options: [
      {
        label: "Game Details Type 1",
        value: "game1",
      },
      {
        label: "Game Details Type 2",
        value: "game1",
      },
      {
        label: "Game Details Type 3",
        value: "game1",
      },
    ],
  },
];
export default function GameDetailsCard() {
  return (
    <AdminRoute>
      <Layout>
        <Card onClick={() => {}} title="Game Details">
          <Box mb={3}>
            <Formik
              initialValues={{
                game_name: "",
                game_details: "",
                game_details_type: "",
                game_id: "",
              }}
              onSubmit={() => {}}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form>
                  <Grid container>
                    {game_details.map(
                      (
                        { key: _, type, options, label, input_type }: FieldType,
                        index: number
                      ) => {
                        const key = _ as keyof GameDetails;
                        switch (type) {
                          case "text":
                            return (
                              <Grid item xs={12} md={12} lg={12}>
                                <Box mt={1} mb={1}>
                                  <TextField
                                    key={index}
                                    label={label}
                                    onChange={handleChange}
                                    value={values[key]}
                                    onBlur={handleBlur}
                                    name={key}
                                    input_type={input_type}
                                  />
                                </Box>
                              </Grid>
                            );
                          case "select":
                            return (
                              <Grid item xs={12} md={12} lg={12}>
                                <Box mt={1} mb={1}>
                                  <Select
                                    key={index}
                                    label={label}
                                    onChange={handleChange}
                                    value={values[key]}
                                    onBlur={handleBlur}
                                    name={key}
                                    options={options ?? []}
                                  />
                                </Box>
                              </Grid>
                            );
                          default:
                            return <></>;
                        }
                      }
                    )}
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Card>
      </Layout>
    </AdminRoute>
  );
}
