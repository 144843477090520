import React, { FC, ReactNode } from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import {
  makeStyles,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import Bg from "images/bg.jpg";

const theme = createTheme({
  overrides: {
    MuiToolbar: {
      root: {
        backgroundColor: "#3f72af",
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundImage: `url(${Bg})`,
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
  },
}));
const Layout: FC = ({ children }: { children?: ReactNode }): JSX.Element => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <NavBar />
        {children}
        <Footer />
      </div>
    </MuiThemeProvider>
  );
};

export default Layout;
