import { useEffect, useState } from "react";
import { getMediaUrl } from "services/media";

const useCopticMedia = ({ gameDetails }: { gameDetails: any }) => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState<any>([]);

  useEffect(() => {
    if (!gameDetails) return;
    const questionsPromises = gameDetails.questions.map(async (q: any) => {
      return { ...q, audio: await getMediaUrl({ fileName: q.audio }) };
    });

    //simply iterate those
    //val will be the result of the promise not the promise itself

    (async () => {
      try {
        const fnalQuestions = [];
        for await (let question of questionsPromises) {
          fnalQuestions.push({ ...question, audio: question.audio.data });
        }
        setQuestions(fnalQuestions);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameDetails]);

  return {
    questions,
    loading,
  };
};

export default useCopticMedia;
