import React from "react";
import AdminGame from "./AdminGame";
import { makeStyles, Grid, Container } from "@material-ui/core";
import clsx from "clsx";
import {
  faSearch,
  faList,
  faFileSignature, 
  faChurch,
} from "@fortawesome/free-solid-svg-icons";

import { GiMaze } from "react-icons/gi";
import {BsFillFileWordFill} from 'react-icons/bs'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 30,
  },
  title: {
    width: "100%",
    color: "#3f72af",
    marginTop: 20,
    marginBottom: 20,
    textAlign: "center",
    fontSize: "2.5rem",
    fontWeight: 500,
  },
  label: {
    width: "100%",
    textAlign: "center",
    fontSize: 20,
    textDecoration:'none'
  },
  icon: {
    borderRadius: "50%",
    padding: 30,
    width: "70% !important",
    height: "100%",
    transition: ".8s all",
    marginBottom: 20,
    border: `1px solid #e93b81`,
    "&:hover": {
      color:"#e93b81",
      background: "#FFFFFF",
      borderColor: "#e93b81",
      borderWidth: 1,
    },
  },
  icon1:{
    backgroundColor: 'blue', 
    color: '#fff', 
    "&:hover": {
      backgroundColor: '#fff', 
      color: 'blue'
    }

  }
}));

export default function Games() {
  const classes = useStyles();
  const adminGames = [
    {
      link: "/admin/coptic",
      label: "Coptic",
      image: faChurch,
      color: "#aa2ee6",
    },
    {
      link: "/admin/matching",
      label: "Matching",
      image: faList,
      color: "#29bb89",
    },
    {
      link: "/admin/fill-blanks",
      label: "Fill in Blanks",
      image:  faFileSignature,
      color: "#FF5DA2",
    }, 
     {
      link: "/admin/maze",
      label: "Maze",
      image: faSearch,
      color: "#e17bd9",
      Externalicon:<GiMaze color="#fff" style={{backgroundColor:"#00d1b2" , border: '1px solid #00d1b2'}}  className={clsx(classes.icon, "link-icon")}/>
    },
    {
      link: "/admin/word-scramble",
      label: "Word Scramble",
      image:  faChurch,
      color: "red",
      Externalicon:<BsFillFileWordFill color="#fff" style={{backgroundColor:"#e93b81", border: '1px solid #e93b81' }} className={clsx(classes.icon, "link-icon")}/>
    }, 
  ];
  return (
    <div className={classes.root}>
      <h2 className={classes.title}>Welcome to Admin's Games Home Page</h2>
      <Container>
        <Grid container spacing={3}>
          {adminGames.map((game, index) => (
            <Grid key={index} item md={4} sm={12} xs={12}>
              <AdminGame {...game} />
            </Grid>
          ))}
          
        </Grid>
      </Container>
    </div>
  );
}