import { makeStyles, Box, Container, Paper, Grid } from "@material-ui/core";
import Layout from "components/Layout";
import { Formik, Form, FormikValues } from "formik";
import { User } from "models/User";
import { FieldType } from "models/Input";
import { TextField, Button, Select } from "components/Shared";
import useDemographic from "hooks/useDemographic";
import Loading from "components/Shared/Loading";
import { ProfileSchema } from "./validations";
import { gradesOptions } from "constants/grades";
import { getProfileByEmail, updateUser } from "services/auth";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import * as msal from "msal";
import { msalAppConfig } from "../../msalAppConfig";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { roles } from "constants/roles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    backgroundColor: "#ebf2f2",
    marginBottom: theme.spacing(4),
  },
  title: {
    fontSize: 25,
    textAlign: "center",
    padding: "10px 0",
    color: "#3f72af",
    fontWeight: 700,
  },
  error: {
    textAlign: "left",
    color: "red",
    paddingTop: "5px",
    textTransform: "capitalize",
  },
  link: {
    color: "#3498db",
    textDecoration: "none",
    fontWeight: "bold",
  },
}));

let fields: FieldType[] = [
  {
    key: "firstName",
    label: "First Name",
    type: "text",
    maxLength: 24,
  },
  {
    key: "lastName",
    label: "Last Name",
    type: "text",
    maxLength: 24,
  },
  {
    key: "country",
    label: "Country",
    type: "select",
    options: [],
  },
  {
    key: "addressLine1",
    label: "address line 1",
    type: "text",
    maxLength: 50,
  },
  {
    key: "addressLine2",
    label: "address line 2",
    type: "text",
  },
  {
    key: "city",
    label: "city",
    type: "text",
    maxLength: 30,
  },
  {
    key: "state",
    label: "state / province",
    type: "select",
    options: [],
  },
  {
    key: "postalZipCode",
    label: "Postal/Zip Code",
    type: "text",
    maxLength: 6,
  },
  {
    key: "email",
    label: "Email Address",
    type: "text",
    maxLength: 50,
    disabled: true,
  },
  {
    key: "phone",
    label: "phone",
    type: "text",
    maxLength: 15,
  },
  {
    key: "grade",
    label: "grade",
    type: "select",
    options: [
      {
        label: "--- Please Select Grade ---",
        value: "",
      },
      ...gradesOptions,
    ],
  },
  {
    key: "church",
    label: "church",
    type: "select",
    options: [],
  },
];

const Profile = () => {
  const { loading, countries } = useDemographic();
  const history = useHistory();
  const classes = useStyles();
  const { addToast } = useToasts();
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    postalZipCode: "",
    country: "",
    email: "",
    phone: "",
    grade: "",
    church: "",
  });
  const [shouldRender, setShouldRender] = useState(false);
  const [finalSchema, setFinalSchema] = useState(ProfileSchema);
  const [profile, setProfile] = useState<any>({});
  useEffect(() => {
    const init = async () => {
      const msalApp = new msal.UserAgentApplication(msalAppConfig);
      const account = msalApp.getAccount();
      const userDetails = account.idToken;
      const profile = await getProfileByEmail(userDetails.emails[0]);
      setProfile({ ...profile.data, email: userDetails.emails[0] });
      const newSchema = ProfileSchema.concat(
        Yup.object().shape({
          grade: Yup.string().required("This field is required"),
          church: Yup.string().required("This field is required"),
        })
      );
      setFinalSchema(newSchema);
      if (account) {
        let state;
        let church;
        const country = countries.find(
          (c) => c.countryCode === profile.data.address.countryCode
        );
        if (country) {
          state = country.stateProvinces.find(
            (s) =>
              s.stateProvinceCode === profile.data.address.stateProvinceCode
          )?.stateProvinceName;
          church = country.stateProvinces
            .find(
              (s) =>
                s.stateProvinceCode === profile.data.address.stateProvinceCode
            )
            ?.churches.find((c) => c.churchName === profile.data.church)
            ?.churchName;
        }
        setInitialValues({
          firstName: profile.data.firstName,
          lastName: profile.data.lastName,
          addressLine1: profile.data.address.addressLine1,
          country: profile.data.address.countryCode,
          state: state ?? "",
          postalZipCode: profile.data.address.postalZipCode,
          email: profile.data.contact.email,
          city: profile.data.address.city,
          grade: profile.data.grade,
          church: church ?? "",
          phone: profile.data.contact.phone,
          addressLine2: profile.data.address.addressLine2,
        });
      }
      setShouldRender(true);
    };

    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  const handleUpdateUser = async (values: FormikValues) => {
    const country = countries.find((c) => c.countryCode === values.country);

    const state = country?.stateProvinces.find(
      (s) => s.stateProvinceName === values.state
    );

    try {
      const { data } = await updateUser({
        profileId: profile.profileId,
        firstName: values.firstName,
        lastName: values.lastName,
        address: {
          city: values.city,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          postalZipCode: values.postalZipCode,
          StateProvinceCode: state?.stateProvinceCode ?? "",
          CountryCode: country?.countryCode,
        },
        contact: {
          phone: values.phone,
          email: profile.email,
        },
        grade: values.grade,
        church: values.church,
        role: roles[profile.role as keyof typeof roles],
      });
      if (data.isSuccess) {
        history.push("/");
      } else {
        addToast(data.errorMessage, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    } catch (error) {
      console.log(error);
      addToast("Something is wrong please try again later", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
  const getStates = (values: any) => {
    if (!countries) return [];
    const country = countries.find((c) => c.countryCode === values.country);
    return (
      country?.stateProvinces.map((s) => ({
        label: s.stateProvinceName,
        value: s.stateProvinceName,
      })) || []
    );
  };

  const getChurchs = (values: any) => {
    const country = countries.find((c) => c.countryCode === values.country);
    if (!countries || !country) return [];
    const state = country.stateProvinces.find(
      (s) => s.stateProvinceName === values.state
    );
    return (
      state?.churches.map((c) => ({
        label: c.churchName,
        value: c.churchId,
      })) || []
    );
  };

  if (!shouldRender) return <Loading open={loading} />;
  return (
    <Layout>
      <Container>
        <Box mt={5}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={finalSchema}
            onSubmit={handleUpdateUser}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className={classes.root}>
                  <Paper className={classes.paper}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h3 className={classes.title}>User Profile</h3>
                      </Grid>
                      {fields.map(
                        (
                          {
                            key: _,
                            type,
                            options,
                            label,
                            input_type,
                            maxLength,
                            disabled,
                          }: FieldType,
                          index: number
                        ) => {
                          const key = _ as keyof User;
                          switch (type) {
                            case "text":
                              return (
                                <Grid item xs={12} md={6} lg={6} key={index}>
                                  <TextField
                                    key={index}
                                    label={label}
                                    onChange={handleChange}
                                    defaultValue={values[key]}
                                    onBlur={handleBlur}
                                    name={key}
                                    input_type={input_type}
                                    inputProps={{
                                      maxLength,
                                    }}
                                    disabled={disabled}
                                  />
                                  {errors[key] && touched[key] ? (
                                    <div className={classes.error}>
                                      {errors[key]?.replace(
                                        "LABEL",
                                        label.replaceAll("_", " ")
                                      )}
                                    </div>
                                  ) : null}
                                </Grid>
                              );
                            case "select":
                              if (key === "country") {
                                return (
                                  <Grid item xs={12} md={6} lg={6} key={index}>
                                    <Select
                                      key={index}
                                      label={label}
                                      onChange={handleChange}
                                      value={values[key]}
                                      onBlur={handleBlur}
                                      name={key}
                                      options={[
                                        {
                                          label:
                                            " --- please select country ---",
                                          value: "",
                                        },
                                        ...countries.map((c) => ({
                                          label: c.countryName,
                                          value: c.countryCode,
                                        })),
                                      ]}
                                    />
                                    {errors[key] && touched[key] ? (
                                      <div className={classes.error}>
                                        {errors[key]?.replace(
                                          "LABEL",
                                          label.replaceAll("_", " ")
                                        )}
                                      </div>
                                    ) : null}
                                  </Grid>
                                );
                              }
                              if (key === "state") {
                                return (
                                  <Grid item xs={12} md={6} lg={6} key={index}>
                                    <Select
                                      key={index}
                                      label={label}
                                      onChange={handleChange}
                                      value={values[key]}
                                      onBlur={handleBlur}
                                      name={key}
                                      options={
                                        values.country
                                          ? [
                                              {
                                                label:
                                                  "--- Please Select State/Province ---",
                                                value: "",
                                              },
                                              ...getStates(values),
                                            ]
                                          : [
                                              {
                                                label:
                                                  "--- Please Select State/Province ---",
                                                value: "",
                                              },
                                            ]
                                      }
                                    />
                                    {errors[key] && touched[key] && (
                                      <div className={classes.error}>
                                        {errors[key]?.replace(
                                          "LABEL",
                                          label.replaceAll("_", " ")
                                        )}
                                      </div>
                                    )}
                                  </Grid>
                                );
                              }
                              if (key === "church") {
                                return (
                                  <Grid item xs={12} md={6} lg={6} key={index}>
                                    <Select
                                      key={index}
                                      label={label}
                                      onChange={handleChange}
                                      value={values[key]}
                                      onBlur={handleBlur}
                                      name={key}
                                      options={
                                        values.state
                                          ? [
                                              {
                                                label:
                                                  "--- Please Select Church ---",
                                                value: "",
                                              },
                                              ...getChurchs(values),
                                            ]
                                          : [
                                              {
                                                label:
                                                  "--- Please Select Church ---",
                                                value: "",
                                              },
                                            ]
                                      }
                                    />
                                    {errors[key] && touched[key] ? (
                                      <div className={classes.error}>
                                        {errors[key]?.replace(
                                          "LABEL",
                                          label.replaceAll("_", " ")
                                        )}
                                      </div>
                                    ) : null}
                                  </Grid>
                                );
                              }
                              return (
                                <Grid item xs={12} md={6} lg={6} key={index}>
                                  <Select
                                    key={index}
                                    label={label}
                                    onChange={handleChange}
                                    value={values[key]}
                                    onBlur={handleBlur}
                                    name={key}
                                    options={options ?? []}
                                  />
                                  {errors[key] && touched[key] ? (
                                    <div className={classes.error}>
                                      {errors[key]}
                                    </div>
                                  ) : null}
                                </Grid>
                              );
                            default:
                              return <></>;
                          }
                        }
                      )}
                      <Grid item xs={12}>
                        <Button text="submit" type="submit" />
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </Form>
            )}
          </Formik>
        </Box>
        <Loading open={loading} />
      </Container>
    </Layout>
  );
};

export default Profile;
