import React from "react";
import ReactDOM from "react-dom";
import Routers from "./routers";
import reportWebVitals from "./reportWebVitals";
import { ToastProvider } from "react-toast-notifications";
import { AuthProvider } from "contexts/AuthContext";

import "css/index.css";

ReactDOM.render(
  <React.StrictMode>
    <ToastProvider placement="bottom-left">
      <AuthProvider>
        <Routers />
      </AuthProvider>
    </ToastProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
