/* eslint-disable */
import styled from "styled-components";
import { useState, useEffect, useCallback, useContext } from "react";
import { Snackbar } from "@material-ui/core";
import Alert, { Color } from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";

import Layout from "components/Layout";
import Loader from "components/Loader";

import generate from "generate-maze";
import UserRoute from "components/UserRoute";
import { updateGamePoints } from "services/games";
import { useLocation } from "react-router-dom";
import AuthContext from "contexts/AuthContext";
import useGame from "hooks/useGame";
import Loading from "components/Shared/Loading";
import useMazeMedia from "hooks/useMazeMedia";

const Wrapper = styled.div`
  width: 60%;
  background-color: #edf6f6;
  border-radius: 10px;
  border: 1px solid #ebeded;
  padding: 20px;
  text-align: center;

  position: absolute;
  top: 120px;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);

  .page-content {
    height: 100%;
  }
`;

const Background = styled.div<{ url: string }>`
  width: 100%;
  height: 90%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("${({ url }) => url}");
  position: relative;
  display: inline-block;
`;

const Board = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  background: #00000060;
  height: 100%;
`;

const Row = styled.div<{
  rowNumbers: number;
}>`
  ${({ rowNumbers }) => {
    return `
    height :${100 / rowNumbers}%;
`;
  }}
`;
const Cell = styled.div<{
  cell: any;
  isLastCell: boolean;
  colNumbers: number;
}>`
  display: inline-block;
  height: 100%;
  box-sizing: border-box;
  border-top: ${({ cell }) => (cell.top ? "1px solid white" : "0px")};
  border-left: ${({ cell }) => (cell.left ? "1px solid white" : "0px")};
  border-right: ${({ cell }) => (cell.right ? "1px solid white" : "0px")};
  border-bottom: ${({ cell }) => (cell.bottom ? "1px solid white" : "0px")};
  ${({ isLastCell, colNumbers }) => {
    return `
    width: ${100 / colNumbers}%;
${
  (isLastCell &&
    `background-image: url(https://cdn.pixabay.com/photo/2016/07/15/04/43/open-doors-1518244__340.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;`) ||
  ""
}`;
  }}
`;

const Character = styled.div<{
  y: number;
  x: number;
  url: string;
  colNumbers: number;
  rowNumbers: number;
}>`
  width: ${({ colNumbers }) => 100 / colNumbers}%;
  height: ${({ rowNumbers }) => 100 / rowNumbers}%;
  position: absolute;
  border-radius: 20%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${({ url }) => url});
  top: ${({ y, rowNumbers }) => y * (100 / rowNumbers)}%;
  left: ${({ x, colNumbers }) => x * (100 / colNumbers)}%;
`;

const Modal = styled.div<{ visible: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0;
  text-align: center;
  background: #00000070;
  z-index: 1;
  display: ${({ visible }) => (visible ? "block" : "none")};
}
`;

const ModalBody = styled.div`
  top: 50%;
  left: 50%;
  color: black;
  position: absolute;
  transform: translate(-50%, -50%);
  height: 125px;
  background-color: white;
  border-radius: 20px;
  width: 50%;
  padding: 15px;
`;

const StyledButton = styled.button`
  margin: 30px;
  color: #fff;
  background-color: #3498db;
  border-color: #3498db;
  padding: 8px 30px;
`;

const Controls = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  text-align: center;
`;

const UpButton = styled(Button)``;

const LeftButton = styled(Button)``;

const RightButton = styled(Button)``;

const DownButton = styled(Button)``;

interface ErrorStatus {
  status?: Color;
  message?: string;
}

export default function Maze(): JSX.Element {
  const { state: routeState } = useLocation() as any;
  const { game, loading: isLoaderOpen } = useGame({ id: routeState.id });
  const { loading, character, background } = useMazeMedia({
    gameDetails: game?.gamesDetails,
  });

  const [open, setOpen] = useState(false);
  const [errorStatus] = useState<ErrorStatus>({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [state, setState] = useState<any>({
    x: 0,
    y: 0,
    maze: [],
  });
  const gameDetails = game ? JSON.parse(game.gamesDetails) : [];

  const {
    state: { id, points, event },
  } = useLocation() as any;
  const { verify: getCurrentUser } = useContext(AuthContext);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOkay = () => {
    handleSubmit();
  };

  const keyDownHandler = useCallback(
    (event) => {
      const { keyCode } = event;

      switch (keyCode) {
        case 37:
          // left
          return (
            !state.maze[state.y][state.x].left &&
            setState({
              ...state,
              x: state.x - 1,
            })
          );
        case 38:
          // top
          return (
            !state.maze[state.y][state.x].top &&
            setState({
              ...state,
              y: state.y - 1,
            })
          );
        case 39:
          // right
          return (
            !state.maze[state.y][state.x].right &&
            setState({
              ...state,
              x: state.x + 1,
            })
          );
        case 40:
          // down
          return (
            !state.maze[state.y][state.x].bottom &&
            setState({
              ...state,
              y: state.y + 1,
            })
          );
        default:
          return state;
      }
    },
    [state]
  );

  useEffect(() => {
    if (
      state.maze.length === 0 &&
      parseInt(gameDetails?.noOfRows) &&
      parseInt(gameDetails?.noOfColumns)
    ) {
      setState({
        ...state,
        maze: generate(
          parseInt(gameDetails?.noOfRows),
          parseInt(gameDetails?.noOfColumns)
        ),
      });
    }
  }, [
    game,
    parseInt(gameDetails?.noOfColumns),
    parseInt(gameDetails?.noOfRows),
    state,
  ]);

  useEffect(() => {
    if (
      gameDetails &&
      state.x === parseInt(gameDetails?.noOfRows) - 1 &&
      state.y === parseInt(gameDetails?.noOfColumns) - 1
    ) {
      showModal();
    } else {
      window.addEventListener("keydown", keyDownHandler);
    }

    return () => window.removeEventListener("keydown", keyDownHandler);
  }, [
    parseInt(gameDetails?.noOfColumns),
    parseInt(gameDetails?.noOfRows),
    keyDownHandler,
    state.x,
    state.y,
  ]);

  const handleSubmit = async () => {
    const user = await getCurrentUser();
    await updateGamePoints({
      gameId: id,
      points,
      eventGradeId: event.eventSimplified.eventGradeId,
      eventId: event.eventSimplified.eventId,
      profileId: user.profileId,
    });
  };

  if (!gameDetails || isLoaderOpen || loading)
    return <Loading open={isLoaderOpen} />;

  return (
    <UserRoute>
      <Layout>
        <Loader isOpen={isLoaderOpen} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          onClose={() => setOpen(false)}
          key={"top right"}
        >
          <Alert onClose={() => setOpen(false)} severity={errorStatus.status}>
            {errorStatus.message}
          </Alert>
        </Snackbar>
        <Modal visible={isModalVisible}>
          <ModalBody>
            <b>Congratulations</b>, you have completed the maze
            <br />
            <StyledButton onClick={handleOkay}>Ok</StyledButton>
          </ModalBody>
        </Modal>
        <Wrapper>
          <div className="page-content">
            <Background style={{ position: "relative" }} url={background || ""}>
              <Board>
                <Character
                  x={state.x}
                  y={state.y}
                  url={character || ""}
                  colNumbers={parseInt(gameDetails.noOfColumns)}
                  rowNumbers={parseInt(gameDetails.noOfRows)}
                />
                {state.maze.map((row: any, rowIndex: number) => (
                  <Row
                    key={rowIndex}
                    rowNumbers={parseInt(gameDetails.noOfRows)}
                  >
                    {row.map((cell: any, cellIndex: number) => (
                      <Cell
                        isLastCell={
                          cellIndex === parseInt(gameDetails.noOfColumns) - 1 &&
                          rowIndex === parseInt(gameDetails.noOfRows) - 1
                        }
                        key={`${rowIndex}-${cellIndex}`}
                        cell={cell}
                        colNumbers={parseInt(gameDetails.noOfColumns)}
                      />
                    ))}
                  </Row>
                ))}
              </Board>
            </Background>
            <Controls>
              <UpButton
                onClick={() =>
                  keyDownHandler({
                    keyCode: 38,
                  })
                }
              >
                &uarr;
              </UpButton>
              <br />
              <LeftButton
                onClick={() =>
                  keyDownHandler({
                    keyCode: 37,
                  })
                }
              >
                &larr;
              </LeftButton>
              <DownButton
                onClick={() =>
                  keyDownHandler({
                    keyCode: 40,
                  })
                }
              >
                &darr;
              </DownButton>
              <RightButton
                onClick={() =>
                  keyDownHandler({
                    keyCode: 39,
                  })
                }
              >
                &rarr;
              </RightButton>
            </Controls>
          </div>
          <br />
        </Wrapper>
      </Layout>
    </UserRoute>
  );
}
